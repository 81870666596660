export const formatDate = (timestamp) => {
  timestamp = parseInt(timestamp);
  let fecha = new Date(timestamp);

  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1;
  let año = fecha.getFullYear();
  let horas = fecha.getHours();
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds();

  if (dia < 10) {
    dia = "0" + dia;
  }
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (horas < 10) {
    horas = "0" + horas;
  }
  if (minutos < 10) {
    minutos = "0" + minutos;
  }
  if (segundos < 10) {
    segundos = "0" + segundos;
  }

  let legibleDate = dia + "/" + mes + "/" + año + " ";
  // let legibleDate = dia + '/' + mes + '/' + año + ' ' + horas + ':' + minutos + ':' + segundos;

  return legibleDate;
};

export const formatDateYY = (timestamp) => {
  timestamp = parseInt(timestamp);
  let fecha = new Date(timestamp);

  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1;
  let año = fecha.getFullYear();
  let horas = fecha.getHours();
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds();

  if (dia < 10) {
    dia = "0" + dia;
  }
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (horas < 10) {
    horas = "0" + horas;
  }
  if (minutos < 10) {
    minutos = "0" + minutos;
  }
  if (segundos < 10) {
    segundos = "0" + segundos;
  }

  let legibleDate = año + "/" + mes + "/" + dia + " ";

  return legibleDate;
};

export const formatDateTime = (timestamp) => {
  timestamp = parseInt(timestamp);
  let fecha = new Date(timestamp);

  let dia = fecha.getDate();
  let mes = fecha.getMonth() + 1;
  let año = fecha.getFullYear();
  let horas = fecha.getHours();
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds();

  if (dia < 10) {
    dia = "0" + dia;
  }
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (horas < 10) {
    horas = "0" + horas;
  }
  if (minutos < 10) {
    minutos = "0" + minutos;
  }
  if (segundos < 10) {
    segundos = "0" + segundos;
  }

  let ampm = horas >= 12 ? "PM" : "AM";
  horas = horas % 12;
  let strTime = horas + ":" + minutos + " " + ampm;

  return strTime;
};

export const formatDateTimeDuration = (
  fechaInicioTimestamp,
  fechaFinTimestamp
) => {
  fechaInicioTimestamp = parseInt(fechaInicioTimestamp);
  fechaFinTimestamp = parseInt(fechaFinTimestamp);

  let fechaInicio = new Date(fechaInicioTimestamp);
  let fechaFin = new Date(fechaFinTimestamp);

  let diferenciaMs = fechaFin - fechaInicio;

  let duracionHoras = diferenciaMs / (1000 * 60 * 60) + " Horas";

  return duracionHoras;
};

export const formateNewDateOfCalendar = (dateUSer) => {
  // console.log("ENTRO AQUI dateUSer", typeof dateUSer);
  if (!(dateUSer instanceof Date) || isNaN(dateUSer.getTime())) {
    return "Seleccione una fecha";
  }

  const dia = dateUSer.getDate();
  const mes = dateUSer.getMonth() + 1;
  const anio = dateUSer.getFullYear() % 100;

  const diaFormateado = dia < 10 ? "0" + dia : dia;
  const mesFormateado = mes < 10 ? "0" + mes : mes;

  return `${diaFormateado}/${mesFormateado}/${anio}`;
};

export const isoDateTourimsDay = (dateString, isoDateStart) => {
  // console.log("entro a", dateString , isoDateStart);

  const [day, month, year] = dateString.split("/").map(Number);

  // Paso 2: Convertir isoDateStart a un objeto Date para extraer la hora y los minutos
  const startDate = new Date(parseInt(isoDateStart, 10));

  // Paso 3: Crear una nueva fecha con la parte de la fecha de dateString y la hora de isoDateStart
  const dateWithTime = new Date(
    year + 2000, // Añadir 2000 para convertir "24" a "2024"
    month - 1, // Restar 1 al mes porque los meses en JS son 0-indexados
    day,
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds(),
    startDate.getMilliseconds()
  );

  // Paso 4: Convertir la fecha resultante a una marca de tiempo Unix (milisegundos desde la época)
  const unixTimestamp = dateWithTime.getTime();

  // console.log("unixTimestamp", unixTimestamp);

  return unixTimestamp;
};
