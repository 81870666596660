
export const searchDataFilter = (data, searchParams) => {
  console.log("searchParams en el nuevo buscador ", searchParams, data);
  let filteredData = [...data];

  const startDateMillis = new Date(searchParams.startDate);
  const endDateMillis = new Date(searchParams.endDate);
  const today = new Date();

  const isoDateStart = startDateMillis.getTime();
  const isoDateEnd = endDateMillis.getTime();
  const isoToday = today.getTime();

  // console.log(
  //   "startDateMillis--------------",
  //   isoDateStart,
  //   "endDateMillis------------------",
  //   isoDateEnd
  // );

  if (searchParams.origin) {
    filteredData = filteredData.filter((item) =>
      item.origen
        ? item.origen.some((place) =>
            place.name.toLowerCase().includes(searchParams.origin.toLowerCase())
          )
        : item.tour.origin.name
            .toLowerCase()
            .includes(searchParams.origin.toLowerCase())
    );
  }

  if (searchParams.destination) {
    filteredData = filteredData.filter((item) =>
      item.destino
        ? item.destino.name
            .toLowerCase()
            .includes(searchParams.destination.toLowerCase())
        : item.tour.destination.name
            .toLowerCase()
            .includes(searchParams.destination.toLowerCase())
    );
  }
  if (searchParams.startDate) {
    filteredData = filteredData.filter((item) => {
      if (item.ida >= isoDateStart) {
        return true;
      } else if (item.start) {
        return isoDateStart >= item.start;
      }
      return false;
    });
  }

  if (searchParams.endDate) {
    filteredData = filteredData.filter((item) => {
      if (item.vuelta <= isoDateEnd) {
        return true;
      } else if (item.end) {
        return isoDateEnd <= item.end;
      }
      return false;
    });
  }

  if (searchParams.availableSeats) {
    filteredData = filteredData.filter((item) => {
      if (item.disponibles) {
        return item.disponibles >= parseInt(searchParams.availableSeats);
      } else if (item.paqueteDiario && item.cuposPorDia) {
        return item.cuposPorDia.some(
          (cupo) => cupo.disponibles >= parseInt(searchParams.availableSeats)
        );
      } else {
        return false;
      }
    });
  }

  if (searchParams.title) {
    // console.log("searchParams.title en el filtro", searchParams.title);
    filteredData = filteredData.filter((item) => {
      return item.nombrepaq?.toLowerCase().includes(searchParams.title.toLowerCase()); 
    });
  }

  return filteredData;
};
