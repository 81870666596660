export const navigation = [
    {
      link: "/",
      name: "Inicio",
      target: 0,
    },
    {
      link: "/",
      name: "Nosotros",
      target: 290,
    },
    {
      link: "/",
      name: "App",
      target: 480,
    },
    {
      link: "/destinos",
      name: "Destinos",
    },
    {
      link: "/turismos",
      name: "Paquetes",
    },
  ];
  