import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const PublicRouter = ({ children }) => {
  const {logged} = useSelector((state) => state.user);
  return logged ? <Navigate to="/banner" /> : children;
};

PublicRouter.propTypes = {
  children: PropTypes.node,
};
