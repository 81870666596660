import logo from "../../../imgs/TuViajeLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";

import { navigation } from "./foo";
import { politNavigation } from "./politicsFoo";


import "./styles.css";

const Footer = () => {
  return (
    <>
      <div className="container_footer">
        <div className="items_footer">
          <div className="rectang">
            <div>
              <p> ACCESO RÁPIDO</p>
              {navigation.map((item, index) => (
                <li  key={index}>
                  <NavLink
                    to={item.link}
                    onClick={() => handleScroll(item.target)}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </div>
          </div>

          <div className="rectang">
            <div>
              <p> LEGALES </p>
              {politNavigation.map((item, index) => (
                <li key={index}>
                  <NavLink 
                    to={item.link}
                    onClick={() => handleScroll(item.target)}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </div>
          </div>

          <div className="rectang">
            <p> SÍGUENOS </p>

            <div className="group-itemFooter cw">
              <FontAwesomeIcon icon={faFacebook} color="#000000" size="xl" />
              <a
                href="https://www.facebook.com/tuviajeapp/"
                style={{ textDecoration: "none", color: "white" }}
              >
                @tuviajeapp
              </a>
            </div>

            <div className="group-itemFooter cw">
              <FontAwesomeIcon icon={faInstagram} color="#000000" size="xl" />
              <a
                href="https://www.facebook.com/tuviajeapp/"
                style={{ textDecoration: "none", color: "white" }}
              >
                @tuviajeapp
              </a>
            </div>
          </div>

          <div className="rectang_img">
            <img className="img_footer" src={logo} alt="Logo" />
          </div>
        </div>
      </div>

      <div className="copyright_foopter">
        Copyright © 2022 All Rights Reserved TuViaje
      </div>
    </>
  );
};

export default Footer;
