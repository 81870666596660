import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    searchItemsTitle : ""
}

const searchItem = createSlice({
    name: "searchItems",
    initialState,
    reducers: {
      getSearchItems: (state, action) => {
        return {
          ...state,
          searchItemsTitle: action.payload.searchItemsTitle
        };
      },
      resetSearchItems: (state) => {
        return {
          ...state,
          searchItemsTitle: ""
        };
      }
    }
  });

export const { getSearchItems , resetSearchItems  } = searchItem.actions;
export default searchItem.reducer;


