import React from "react";
import "./styles.css";
import { NavLink } from "react-router-dom";

export const CheckBoxCustom = ( { checked, onChange , onBlur, id, name  } ) => {
  return (
    <div className="container_checkBoxCustom">
      <label className="checkBoxCus_label">
        <input type="checkbox" className="checkBoxCus_input" checked={checked} onChange={onChange} onBlur={ onBlur} id ={ id } name={ name }/>
        <span className="checkBoxCus_spam"></span>
      </label>
      <div className="container_text_checkBoxCustom">
        Acepto&nbsp;
        <NavLink to={"/politicas"} style={{ color: "#EC920B" }}>
          términos y condiciones
        </NavLink>
      </div>
    </div>
  );    
};
