import React from 'react'
import './styles.css'

export const InputsCustomsForm = ({onChange , onBlur , value , title , touched , errors , textError , id, name , type, htmlFor , containerClass }) => {
  return (
    <div className={containerClass}>
    <label htmlFor={htmlFor} className="label_suscriptors">
      {title}
    </label>
    <input
      id={ id }
      name={ name }
      type={ type }
      onChange={onChange}
      value={value}
      className="input_suscriptors"
      onBlur={onBlur}
    />
    <div className="spam_invicible">
      {touched &&
        errors != textError && (
          <span className="form_suscription_validations">
            {errors}
          </span>
        )}
    </div>
    </div>
  )
}



