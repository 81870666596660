import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "./styles.css";

export const CardCharacteristics = ({
  description = "por favor escriba texto",
  heightIcon = 60,
  tittle = "titulo?",
  iconName = faUser,
  border = 1,
}) => {
  return (
    <div className={`container-CardCharacteristics border${border}`}>
      <div>
        <FontAwesomeIcon
          icon={iconName}
          style={{ height: heightIcon }}
          color="#E2991C"
        />
      </div>

      <div className="texts">
        <h1> {tittle} </h1>
        <p> {description} </p>
      </div>
    </div>
  );
};
