import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { formatPrice } from "../../../utils/formatPrice";
import "./styles.css";

const linkURLTurims = process.env.REACT_APP_IMAGE_URL;

export const CustomCardCoordinator = ({
  name,
  day,
  night,
  description,
  price,
  img,
  ubi,
  typename,
  onClick,
  start,
  end,
}) => {
  // console.log(
  //   "ESTO ES LO QUE RECIBE EL CUSTOM CARD COORDINATOR",
  //   name,
  //   day,
  //   night,
  //   description,
  //   "EL PRECIO",
  //   price,
  //   "la imagen",
  //   img,
  //   ubi,
  //   "el tipo ",
  //   typename,
  //   "el start",
  //   start,
  //   "el end",
  //   end
  // );

  const startTimestamp = `${start}`;
  const endTimestamp = `${end}`;

  const startDate = new Date(parseInt(startTimestamp, 10));
  const endDate = new Date(parseInt(endTimestamp, 10));

  const diferenciaEnMilisegundos = endDate - startDate;

  const dias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
  const horasRestantes = Math.floor(
    (diferenciaEnMilisegundos % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  return (
    <div className="container_CustomCardCoordinator">
      <div className="item_one_CustomCardCoordinator">
        <img
          className="imgcustom_CustomCardCoordinator"
          src={
            img == null || img == undefined || img == ""
              ? `${linkURLTurims}/logo_negro.png`
              : `${linkURLTurims}${img}`
          }
          alt=""
        />
      </div>

      <div className="item_two_CustomCardCoordinator">
        <p className="item_two_CustomCardCoordinator_title">
          {name.substring(0, 20)}{" "}
          {typename === "ProgrammingPopulateResponse"
            ? ` - ${ubi.substring(0, 10)}`
            : ""}
        </p>

        <div className="ubication_row">
          {typename == "ProgrammingPopulateResponse" && (
            <div className="Days_nights_card">
              <p className="item_two_CustomCardCoordinator_text">
                <ion-icon name="location-outline"></ion-icon>
                {ubi.substring(0, 5)}
              </p>

              <p className="item_two_CustomCardCoordinator_text">
                <FontAwesomeIcon icon={faClock} />
                {dias == 0 ? "Hora(s): " + horasRestantes : "Día(s): " + dias}
              </p>
            </div>
          )}

          {typename == "TourismPopulateResponseView" && (
            <>
              <p className="item_two_CustomCardCoordinator_text_ubi">
                <ion-icon name="location-outline"></ion-icon>
                {ubi.substring(0, 5)}
              </p>
              <div className="Days_nights_card">
                <p className="item_two_CustomCardCoordinator_text">
                  <ion-icon name="sunny-outline"></ion-icon>
                  {day} dia(s)
                </p>
                <p className="item_two_CustomCardCoordinator_text">
                  <ion-icon name="moon-outline"></ion-icon>
                  {night} noche(s)
                </p>
              </div>
            </>
          )}
        </div>

        <p className="item_two_CustomCardCoordinator_descrption">
          {description.substring(0, 100)}...
        </p>
        <div className="item_two_price_">
          <p className="item_two_CustomCardCoordinator_price ">Precio </p>
          <p className="item_two_CustomCardCoordinator_price_ ">
            {formatPrice(price)}
          </p>
        </div>

        <button
          className="item_two_CustomCardCoordinator_button"
          onClick={onClick}
        >
          Reservar ahora <ion-icon name="arrow-forward-outline"></ion-icon>
        </button>
      </div>
    </div>
  );
};
