import { useState } from "react";
import { NavLink } from "react-router-dom";
import { setLoggedUser, logoutUser } from "../../../redux/userSlice";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { nodeName } from "jquery";

export const SubMenu = () => {
  const userName = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return userName.logged ? (
    <div>
      <div className="icon_login">
        <NavLink
          to="/"
          className="nav-link"
          onClick={() => {
            dispatch(logoutUser());
            Swal.fire({
              title: "Sesión cerrada correctamente",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }}
        >
          Cerrar sesión
        </NavLink>
        <ion-icon className="icoon" name="log-in-outline"></ion-icon>
      </div>
      <h5 className="user-names-subMenu">{userName.names}</h5>
    </div>
  ) : (
    <div className="icon_login">
      <NavLink to="/Login" className="nav-link">
        Iniciar sesión
      </NavLink>
      <ion-icon name="log-in-outline"></ion-icon>
    </div>
  );
};
