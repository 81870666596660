import { Principal } from "../../components/MainInformation/Principal/Principal";
import { RecommendedFilters } from "../../components/MainInformation/RecommendedFilters/RecommendedFilters";
import { AboutUs } from "../../components/MainInformation/AboutUs/AboutUs";
import { Characteristics } from "../../components/MainInformation/Characteristics/Characteristics";
import { YouTavel } from "../../components/MainInformation/YouTravel/YouTravel";
import { Suscriptors } from "../../components/MainInformation/Suscriptors/Suscriptors";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { LottieAnimation } from "../../components/LottieAnimation/LottieAnimation";
import "../../App.css";
import { SocialMedia } from "../../components/SocialMedia/SocialMedia";

export const Banner = () => {
  return (
    <>
      <SocialMedia />
      <Principal />
      <RecommendedFilters />
      <AboutUs />
      <Characteristics />
      <YouTavel />
      <Suscriptors />
    </>
  );
};
