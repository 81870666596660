import { Button } from "react-bootstrap";
import logo from "../../imgs/TuViajeLogo.png";
import { useEffect, useState } from "react";
import { ordersFacturationProgramming } from "../../services/ordersFacturationProggraming";
import { ordersFacturation } from "../../services/ordersFacturation";
import Footer from "../../components/MainInformation/Footer/Footer";
import { formatDate, formatDateTime } from "../../utils/formatDate";
import { isMobile } from "react-device-detect";

import "./styles.css";
import { downLoadPDF } from "../../services/downLoadPDF";
import Swal from "sweetalert2";

const urlPDF = process.env.REACT_APP_PDF_URL;
const server = process.env.REACT_APP_SERVER;
const urlPRincipal = process.env.REACT_APP_URL_COMERCIAL;

export const WebView = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [data, setData] = useState({});
  const [event, setEvent] = useState(false);

  useEffect(() => {
    // console.log("Es un dispositivo móvil:", isMobile);
    hideElements();
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem("paymentData");
    // console.log("Datos en localStorage:", storedData);
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setPaymentData(parsedData);

        if (parsedData?.campoExtra1 === "Turismo") {
          ordersFacturation(parsedData?.codigoFactura).then((result) => {
            setData(result);
          });
        } else {
          // console.log("Esto es destino Socio");
          ordersFacturationProgramming(parsedData?.codigoFactura).then(
            (result) => {
              setData(result);
            }
          );
        }
      } catch (error) {
        console.error("Error al analizar los datos almacenados:", error);
      }
    } else {
      Swal.fire({
        title: "No se registran datos de pago",
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
      window.location.href = urlPRincipal;
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      // console.log("Evento popstate disparado, eliminando paymentData de localStorage");
      localStorage.removeItem("paymentData");
      // console.log("paymentData eliminado de localStorage");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      // console.log("Listener de evento popstate eliminado");
    };
  }, []);

  useEffect(() => {
    // console.log("El estado del evento ha cambiado, valor actual:", event);
    if (event) {
      // console.log("Eliminando paymentData de localStorage debido al cambio de evento");
      localStorage.removeItem("paymentData");
      // console.log("paymentData eliminado de localStorage");
    }
  }, [event]);


  
  const handleDownloadPDF = () => {
    downLoadPDF(paymentData?.codigoFactura, paymentData?.campoExtra1)
      .then((result) => {
        // console.log("result ya despues del consumo del servicio de PDF", result, "este es la URL del serverPDF", urlPDF);
        if (!isMobile) {
          const downloadLink = document.createElement("a");
          downloadLink.href = `${urlPDF}${data.orderID}.pdf`;
          downloadLink.target = "_blank";
          downloadLink.click();

          setEvent(true);
        } else {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "PDF", pdfFileName: `${data.orderID}.pdf`, mensaje: "Se mando mensaje al mobil" })
          );
        }
      })
      .catch((error) => {
        console.log("error al descargar", error);
      })
      .finally(() => {
        console.log("Descarga finalizada ENTRO AL FINALY");
      });
  };

  
  const handleBack = () => {
    if (isMobile) {
      cerrarWebView();
    } else {
      window.location.href = urlPRincipal;
    }
  };

  const cerrarWebView = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "cerrarWebView" })
    );
  };

  const hideElements = () => {
    if (isMobile) {
      // console.log("esta conectado desde un dispositivo movil");
      const NAV = document.getElementById("navBarLogo");
      const FTR = document.getElementById("ftr");
      if (NAV) NAV.href = "#";
      if (FTR) FTR.style.display = "none";
    } else {
      console.log("esta conectado desde un ordenador");
    }
  };

  return (
    <div className="container-webView">
      <div className="webView-logo">
        <img src={`${logo}`} alt="Logo YouTravel" />
      </div>

      <div className="item-one-webView">
        <h1>Se está validando tu pago</h1>
        <h1>Gracias por tu compra!!</h1>
        <h2>¡Te esperamos en nuestra página web!</h2>
        <div className="buttons-webVier-container">
          <Button
            type="button"
            onClick={handleBack}
          >
            Regresa a TuViajeApp
          </Button>
          <Button
            type="button"
            onClick={handleDownloadPDF}
          >
            Descargar comprobante de pago
          </Button>
        </div>
      </div>

      <div className="item-two-webView-container">
        <h1>Datos de la compra</h1>
        <div className="item-tree-webView-containe">
          <div className="information-webView-users">
            <h3 className="title_webView-users_facturation">Código Factura:</h3>
            <h3 className="text_webView-users_facturation">{data.orderID || "N°"}</h3>
          </div>

          <div className="information-webView-users">
            <h3 className="title_webView-users_facturation">Estado Factura:</h3>
            <h3 className="text_webView-users_facturation">{data.statusPayment || "Procesando..."}</h3>
          </div>

          {paymentData?.campoExtra1 === "Turismo" && (
            <div className="information-webView-users">
              <h3 className="title_webView-users_facturation">Nombre del Paquete:</h3>
              <h3 className="text_webView-users_facturation">{data.tourism?.nombrepaq || "Procesando......"}</h3>
            </div>
          )}

          {paymentData?.campoExtra1 === "Turismo" && (
            <div className="information-webView-users">
              <h3 className="title_webView-users_facturation">Fecha:</h3>
              <h3 className="text_webView-users_facturation">{formatDate(data.start) || "Procesando......"}</h3>
            </div>
          )}

          {paymentData?.campoExtra1 === "Turismo" && (
            <div className="information-webView-users">
              <h3 className="title_webView-users_facturation">Hora:</h3>
              <h3 className="text_webView-users_facturation">{formatDateTime(data.start) || "Procesando......"}</h3>
            </div>
          )}

          {paymentData?.campoExtra1 === "Destino" && (
            <>
              <div className="information-webView-users">
                <h3 className="title_webView-users_facturation">Origen:</h3>
                <h3 className="text_webView-users_facturation">{data.programming?.tour?.origin?.name || "Pendiente"}</h3>
              </div>

              <div className="information-webView-users">
                <h3 className="title_webView-users_facturation">Destino:</h3>
                <h3 className="text_webView-users_facturation">
                  {data.programming?.tour?.destination?.name || "Pendiente"}
                </h3>
              </div>

              <div className="information-webView-users">
                <h3 className="title_webView-users_facturation">Fecha:</h3>
                <h3 className="text_webView-users_facturation">
                  {formatDate(data.programming?.start) || "Procesando......"}
                </h3>
              </div>

              <div className="information-webView-users">
                <h3 className="title_webView-users_facturation">Hora:</h3>
                <h3 className="text_webView-users_facturation">
                  {formatDateTime(data.programming?.start) ||
                    "Procesando......"}
                </h3>
              </div>
            </>
          )}

          <div className="information-webView-users">
            <h3 className="title_webView-users_facturation">Cantidad de pasajeros:</h3>
            <h3 className="text_webView-users_facturation">{data.quantity || "Procesando..."}</h3>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
