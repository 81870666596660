import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser } from "@fortawesome/free-regular-svg-icons";

const linkURL =  process.env.REACT_APP_IMAGE_URL

export const CardCustom = ({
  name,
  avaliable,
  price,
  img,
  start,
  end,
  origin,
  destination,
  pricedcto,
  dayTurims,
  handleSubmit,
}) => {
  const startTimestamp = `${start}`;
  const endTimestamp = `${end}`;

  const startDate = new Date(parseInt(startTimestamp, 10));
  const endDate = new Date(parseInt(endTimestamp, 10));

  const diferenciaEnMilisegundos = endDate - startDate;

  const dias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
  const horasRestantes = Math.floor(
    (diferenciaEnMilisegundos % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  return (
    <div className="container_itemCardCustom">
      <div className="cardCustom__img">
        <img
          className="imgcustom"
          src={img != "" ? `${linkURL}${img}` : `${linkURL}logo_negro.png`}
          alt=""
        />
      </div>

      <div className="info-items-custom">
        <div className="cardCustom__name">
          <p> {name ? name.substring(0, 20) : `${origin} - ${destination}`}</p>
        </div>

        <div className="itemsday">
              <div className="center_text_and_Icon">
            <FontAwesomeIcon icon={faUser} />
          <div className="cardCustom__day">
            <p className="CenterP">{avaliable == 0 ? " Cupos a Diarios" : avaliable + " cupos"}</p>
          </div>

              </div>
          <div className="center_text_and_Icon">
          
            <FontAwesomeIcon icon={faClock} />
          <div className="cardCustom__day">
            <p className="CenterP">
              {dayTurims
                ? "Días : " + dayTurims + " "
                : dias === 0
                ? `Horas: ${horasRestantes}`
                : `${dias} Días`}
            </p>
          </div>
          </div>

        </div>

        <div
          className={
            pricedcto != null
              ? "cardCustom__precio discounted"
              : "cardCustom__precio centered"
          }
        >
          <p
            className={
              pricedcto != null ? "normalPrice discounted" : "normalPrice"
            }
          >
            {`${price}`}
          </p>
          <p>{pricedcto != null ? pricedcto : null}</p>
        </div>
      </div>
      <button className="btnCardCustom" onClick={handleSubmit}>
        Reservar
      </button>
    </div>
  );
};
