export const politNavigation = [
    {
      link: "/politicas",
      name: "Términos y condiciones",
      target: 0,
    },
    {
      link: "/politicas",
      name: "Política de privacidad",
      target: 290,
    },
    {
      link: "/politicas",
      name: "Tratamiento de datos",
      target: 480,
    },
  ];