import "./style.css";

import { useEffect, useState } from "react";
import { getAllDestinations } from "../../../services/destinations";
import { getAllTurims } from "../../../services/turims";
import { Carrusel } from "./Carrusel";


export const RecommendedFilters = () => {
  const [destinations, setDestinations] = useState([]);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    getAllDestinations()
      .then((data) => {
        setDestinations(data);
      })
      .catch((error) => {
        console.error("Error al obtener destinos:", error);
      })
      .finally(() => {

        getTurims();
       
      });
  }, []);

  const getTurims = async () => {
    try {
      const response = await getAllTurims();
      // console.log("data suprema", response[0].vuelta);
      const today = new Date().getTime();
      const filteredResponse = response.filter((turim) => {
        const vueltaDate = parseInt(turim.vuelta, 10);
        return vueltaDate >= today;
      });

      setTours(filteredResponse);
    } catch (error) {
      console.error("Error fetching turims:", error);
    }
  };

  return (
    <div className="containerRecommended">
      <Carrusel title={"Turismos recomendados"} data={tours} />
      <Carrusel title={"Destinos recomendados"} data={destinations} />
    </div>
  );
};
