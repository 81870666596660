import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const registerPassenger = async (name, email, password) => {
  
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
            mutation{
                createPassenger(input:{
                names: "${name}"
                lastName: ""
                email: "${email}"
                password: "${password}"
                profile: "user"
                }
              ){
                result{
                  id
                }
                message 
              }
            }
            `,
      })
      .then((result) => {
        // console.log("result mano", result);
        if (result.data.createPassenger.result !== null) {
          // console.log("entro en el 1");
          resolve(result.data.createPassenger.result);
        } else {
          // console.log("entro en el 2");
          reject(result.data.createPassenger.message);
        }
      });
  });
};
