import Swal from "sweetalert2";

export const loading = () => {
    Swal.fire({
        title: "Cargando...",
        timer: 2000,
        didOpen: () => {
            Swal.showLoading();
        },
    });
 
}