
export const dataFilter = (data , status) => {

    // console.log("HOOOOOOK", data, status);
    let filteredData = [...data];

    switch (status) {
      case "lowerPrice":
        filteredData.sort((a, b) => (a.price || a.precio) - (b.price || b.precio));
        break;
      case "higherPrice":
        filteredData.sort((a, b) => (b.price || b.precio) - (a.price || a.precio));
        break;
        case "origin-AZ":
          filteredData.sort((a, b) => {
            const nameA = (a.tour?.origin?.name || a.origen?.name || '').toLowerCase();
            const nameB = (b.tour?.origin?.name || b.origen?.name || '').toLowerCase();
            // console.log("nameA", nameA);
            // console.log("nameB", nameB);
    
            return nameA.localeCompare(nameB);
          });
          break;
        case "origin-ZA":
          filteredData.sort((a, b) => {
            const nameA = (a.tour?.origin?.name || a.origen?.name || '').toLowerCase();
            const nameB = (b.tour?.origin?.name || b.origen?.name || '').toLowerCase();
    
            return nameB.localeCompare(nameA);
          });
          break;

          case "destination-AZ" :
            filteredData.sort((a, b) => {
              const nameA = (a.tour?.destination?.name || a.destino?.name || '').toLowerCase();
              const nameB = (b.tour?.destination?.name || b.destino?.name || '').toLowerCase();
              // console.log("nameA", nameA);
              // console.log("nameB", nameB);
      
              return nameA.localeCompare(nameB);
            });
            break;

          case "destination-ZA" :
            filteredData.sort((a, b) => {
              const nameA = (a.tour?.destination?.name || a.destino?.name || '').toLowerCase();
              const nameB = (b.tour?.destination?.name || b.destino?.name || '').toLowerCase();
      
              return nameB.localeCompare(nameA);
            });
            break;

            case "available":
                filteredData.sort((a, b) => (a.price || a.disponibles) - (b.price || b.disponibles));
              break;
          

              default:
        break;
    }

    return filteredData;
}