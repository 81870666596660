export const navigation = [
  {
    link: "/",
    name: "Inicio",
    target: 0,
  },
  {
    link: "/",
    name: "Nosotros",
    target: 300,
  },
  {
    link: "/",
    name: "App",
    target: 500,
  },
  {
    link: "/destinos",
    name: "Destinos",
  },
  {
    link: "/turismos",
    name: "Paquetes",
  },
  
  
];
