import { useState } from "react";
import { loginPassenger } from "../../services/login";
import regex from "../../utils/regex";
import Swal from "sweetalert2";
import { setLoggedUser } from "../../redux/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { registerPassenger } from "../../services/register";

import "./styles.css";

export const Login = () => {
  const dispatch = useDispatch();

  const [deslizado, setDeslizado] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [registerUser, setRegisterUser] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showRegisterPassword, setShowRegisterPassword] = useState(false);
  const [showRegisterConfirmPassword, setShowRegisterConfirmPassword] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      handleLoginClick();
    }
  };

  const handleKeyDownRegister = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); 
      handleRegisterClick();  
    }
  }

  const handleDeslizar = () => {
    setDeslizado(!deslizado);
  };
  const handleLoginClick = () => {
    if (regex.regexEmail.test(email) && regex.regexPassword.test(password)) {
      // console.log("email", email, "password", password);

      loginPassenger(email, password)
        .then((userData) => {
          dispatch(
            setLoggedUser({
              role: userData.profile,
              logged: true,
              id: userData.id,
              names: userData.names,
              lastName: userData.lastName,
              email: userData.email,
              phone: userData.phone,
              tokenPush: userData.token,
              status: userData.status,
              profile: userData.profile,
            })
          );

          Swal.fire({
            title: "Sesión abierta correctamente",
            icon: "success",
            showConfirmButton: false, 
            timer: 1500
          });

          
        })
        .catch((error) => {
          console.error("Error en el inicio de sesión:", error);
          Swal.fire({
            icon: "error",
            title: "¡ERROR!",
            text: "Datos Incorrectos",
          });
        }).finally(() => {
        }
        );
    } else {
      if (!regex.regexEmail.test(email)) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Correo electrónico inválido. Por favor, ingresa un correo válido.",
        });
      } else if (!regex.regexPassword.test(password)) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Contraseña inválida.  /n caracteres validos:  *-_@ .  Por favor, ingresa una contraseña válida.",
        });
      }
    }
  };

  const handleRegisterClick = () => {
    // console.log(
    //   "ENTRANDO A REGISTRAR",
    //   registerEmail,
    //   registerPassword,
    //   registerUser,
    //   registerConfirmPassword
    // );

    if (
      regex.regexName.test(registerUser) &&
      regex.regexEmail.test(registerEmail) &&
      regex.regexPassword.test(registerPassword) &&
      regex.regexPassword.test(registerConfirmPassword) &&
      registerPassword === registerConfirmPassword
    ) {
      // console.log("email", registerEmail, "password", registerPassword);
      registerPassenger(registerUser, registerEmail, registerPassword)
        .then((registerData) => {
          // console.log("registerData", registerData);

          Swal.fire({
            icon: "success",
            title: "¡ÉXITO!",
            text: "Registro exitoso",
            timer: 1500,
            showConfirmButton: false,
          });

          loginPassenger(registerEmail, registerPassword)
            .then((userData) => {
              dispatch(
                setLoggedUser({
                  role: userData.profile,
                  logged: true,
                  id: userData.id,
                  names: userData.names,
                  lastName: userData.lastName,
                  email: userData.email,
                  phone: userData.phone,
                  tokenPush: userData.token,
                  status: userData.status,
                  profile: userData.profile,
                })
              );
            })
            .catch((error) => {
              console.error("Error en el inicio de sesión:", error);
              Swal.fire({
                icon: "error",
                title: "¡ERROR!",
                text: "Datos Incorrectos",
              });
            });
        })
        .catch((error) => {
          console.error(
            "Error en el registro:",
            error,
            "registerUser",
            registerUser,
            "registerEmail",
            registerEmail,
            "registerPassword",
            registerPassword,
            "registerConfirmPassword",
            registerConfirmPassword
          );

          Swal.fire({
            icon: "error",
            title: "¡ERROR!",
            text: `${error} `,
          });
        });
    } else {
      if (!regex.regexName.test(registerUser)) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Nombre inválido. Por favor, ingresa un nombre válido.",
        });
      } else if (!regex.regexEmail.test(registerEmail)) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Correo electrónico inválido. Por favor, ingresa un correo válido.",
        });
      } else if (!regex.regexPassword.test(registerPassword)) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Contraseña inválida. Caracteres especiales válidos: *-_@ Por favor, ingresa una contraseña válida de 5 a 10 caracteres.",
        });
      } 
      else if (registerPassword !== registerConfirmPassword) {
        Swal.fire({
          icon: "error",
          title: "¡ERROR!",
          text: "Las contraseñas no coinciden. Por favor, ingresa una contraseña válida.",
        });
      }
    }
  };

  return (
    <div className={`container_login ${deslizado ? "deslizado" : ""}`}>
      <button
        className="boton_back_Login"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <ion-icon className="back_login" name="arrow-back-outline"></ion-icon>
      </button>
      <div className="login_item_row">
        <div className="login_item_one">
          <div className="login_item">
            <div className="login_item_title">
              <h1>Iniciar sesión</h1>
            </div>
            <div>
              <input
                className="login_item_input"
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                className="login_item_input"
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
               <ion-icon
                className="toggle-password-icon"
                name={showPassword ? "eye-off-outline" : "eye-outline"}
                onClick={() => setShowPassword(!showPassword)}
              ></ion-icon>
            </div>
            <div className="login_item_button">
              <button
                className="login_item_one_button"
                onClick={() => {
                  handleLoginClick();
                }}
              >
                Ingresar
              </button>
            </div>
          </div>
        </div>

        <div className="login_item_three">
          <div className="login_item_two">
            <div className="login_item_two_title">
              <h1 className="title_login_item_two">{`${
                deslizado ? "¿ YA TIENES CUENTA ?" : "¿ AÚN NO TIENES CUENTA ?"
              }`}</h1>
            </div>

            <div>
              <button
                className="login_item_two_button"
                onClick={handleDeslizar}
              >
                {`${deslizado ? "Iniciar" : "Regístrate"}`}
              </button>
            </div>
          </div>

          <div className="login_item">
            <div className="login_item_title">
              <h1>Regístrate</h1>
            </div>
            <div>
              <input
                className="login_item_input"
                type="text"
                placeholder="Nombre"
                onChange={(e) => setRegisterUser(e.target.value)}
              />
            </div>
            <div>
              <input
                className="login_item_input"
                type="text"
                placeholder="Email"
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                className="login_item_input"
                type={showRegisterPassword ? "text" : "password"}
                placeholder="Contraseña"
                onChange={(e) => setRegisterPassword(e.target.value)}
                onKeyDown={handleKeyDownRegister}
              />
                <ion-icon
                className="toggle-password-icon"
                name={showRegisterPassword ? "eye-off-outline" : "eye-outline"}
                onClick={() => setShowRegisterPassword(!showRegisterPassword)}
              ></ion-icon>
            </div>
            <div>
              <input
                className="login_item_input"
                type={showRegisterConfirmPassword ? "text" : "password"}
                placeholder="Confirmar contraseña"
                onChange={(e) => setRegisterConfirmPassword(e.target.value)}
                onKeyDown={handleKeyDownRegister}
              />
               <ion-icon
                className="toggle-password-icon"
                name={showRegisterConfirmPassword ? "eye-off-outline" : "eye-outline"}
                onClick={() => setShowRegisterConfirmPassword(!showRegisterConfirmPassword)}
              ></ion-icon>
            </div>
            <div className="login_item_button">
              <button
                className="login_item_one_button"
                onClick={() => {
                  handleRegisterClick();
                }}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
