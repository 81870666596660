import React, { useState } from "react";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import regex from "../../../utils/regex";
import { CheckBoxCustom } from "../../CheckBoxCustom/CheckBoxCustom";
import { suscriptions } from "../../../services/suscriptions";
import "./styles.css";
import { InputsCustomsForm } from "../../InputsCustomsForm/InputsCustomsForm";

const initialValues = {
  firstName: "",
  secondName: "",
  email: "",
  phone: "",
  message: "",
  terms: false,
};

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(4, "*El nombre debe tener al menos 4 caracteres")
    .max(20, "Sólo se permiten 20 caracteres")
    .matches(regex.regexName, "*Sólo se permiten letras")
    .required("*El nombre es requerido"),
  secondName: Yup.string()
    .min(4, "*El apellido debe tener al menos 4 caracteres")
    .max(20, "Sólo se permiten 20 caracteres")
    .matches(regex.regexName, "*Sólo se permiten letras")
    .required("*El apellido es requerido"),
  email: Yup.string()
    .matches(regex.regexEmail, "*Correo electrónico no válido")
    .required("*Ingrese el correo electrónico"),
  phone: Yup.string()
    .nullable()
    .required("*Digite el número de celular")
    .matches(regex.regexPhone, "*Número de celular no válido"),
  message: Yup.string()
    .min(5, "*El mensaje debe tener al menos 5 caracteres")
    .max(100, "*Sólo se permiten 100 caracteres")
    .required("*Escriba un mensaje"),
  terms: Yup.boolean().oneOf(
    [true],
    "*Debes aceptar los términos y condiciones"
  ),
});

const handleSubmit = async (values) => {
  const emailJsPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const emailJsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const emailJsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;

  const templateParams = {
    from_name: `${values.firstName} ${values.secondName}`,
    email: values.email,
    phone: values.phone,
    message: values.message,
  };

  try {
    const response = await emailjs.send(
      emailJsServiceId,
      emailJsTemplateId,
      templateParams,
      emailJsPublicKey
    );
    Swal.fire({
      icon: "success",
      title: "¡ÉXITO!",
      text: "Correo enviado con éxito",
      timer: 1500,
      showConfirmButton: false,
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "¡ERROR!",
      text: "Correo no enviado",
    });
  }
};

export const Suscriptors = () => {
  const [validateEmailUSer, setValidateEmailUSer] = useState("");

  const handleSuscriptors = async () => {
    if (!regex.regexEmail.test(validateEmailUSer)) {
      Swal.fire({
        icon: "error",
        title: "¡ERROR!",
        text: "Correo electrónico no válido",
      });
      return;
    }

    const response = await suscriptions(validateEmailUSer);
    if (response) {
      Swal.fire({
        icon: "success",
        title: "¡ÉXITO!",
        text: "Correo suscrito con éxito",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="container_suscriptors">
      <div className="suscriptors_item_one">
        <h1>Subscríbete a nuestro boletín</h1>
        <p className="text_suscriptors">
          Suscríbete a nuestro boletín y recibe las mejores ofertas de viajes,
          hoteles y planes turísticos.
        </p>

        <div>
          <input
            id="emailUser"
            name="emailUser"
            type="text"
            className="input_email_suscriptors"
            placeholder="Correo electrónico"
            value={validateEmailUSer}
            onChange={(e) => {
              setValidateEmailUSer(e.target.value);
              // console.log(e.target.value);
            }}
          />
          <button
            className="button_email_suscriptors"
            onClick={handleSuscriptors}
          >
            Suscribir
          </button>
        </div>
      </div>

      <div className="suscriptors_item_two">
        <div className="contact-us">
          <h1>Contáctanos</h1>
          <p>
            Si deseas conocer todos los planes que tenemos para ti, déjanos tus
            datos para tener el gusto de contactarte... Somos la aplicación
            móvil que te brindará la seguridad, comodidad y rapidez de encontrar
            el viaje de tus sueños en un solo lugar: Tu Viaje App.
          </p>

          <div className="contact-info">
            <div className="group-item">
              <FontAwesomeIcon icon={faLocationDot} color="#000000" size="xl" />
              <p>
                Cra. 83c #17-52 <br /> Cali, Valle del Cauca
              </p>
            </div>

            <div className="group-item">
              <FontAwesomeIcon icon={faEnvelope} color="#000000" size="xl" />
              <p>comercial@tuviajeapp.co</p>
            </div>
            <div className="group-item">
              <FontAwesomeIcon icon={faPhone} color="#000000" size="xl" />
              <p>(310)-6709314</p>
            </div>
          </div>
        </div>

        <div className="form-contactUS">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setTouched,
              validateForm,
              submitForm,
              resetForm,
            }) => (
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const formErrors = await validateForm();
                  if (Object.keys(formErrors).length === 0) {
                    await submitForm();
                    resetForm();
                  } else {
                    setTouched({
                      firstName: true,
                      secondName: true,
                      email: true,
                      phone: true,
                      message: true,
                      terms: true,
                    });
                    Swal.fire({
                      icon: "error",
                      title: "¡ERROR!",
                      text: "Por favor completar todos los campos",
                    });
                  }
                }}
                className="form_suscriptors_users"
              >
                <div className="phone">
                  <InputsCustomsForm
                    containerClass={"items-suscriptors"}
                    id={"firstName"}
                    name={"firstName"}
                    type={"text"}
                    htmlFor={"firstName "}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    title={"Nombre"}
                    touched={touched.firstName}
                    errors={errors.firstName}
                    textError={"*El nombre es requerido"}
                  />
                  <InputsCustomsForm
                    containerClass={"items-suscriptors"}
                    id={"secondName"}
                    name={"secondName"}
                    type={"text"}
                    htmlFor={"secondName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.secondName}
                    title={"Apellido"}
                    touched={touched.secondName}
                    errors={errors.secondName}
                    textError={"*El apellido es requerido"}
                  />
                </div>

                <div className="phone">
                  <InputsCustomsForm
                    containerClass={"items-suscriptors"}
                    htmlFor={"email"}
                    id={"email"}
                    name={"email"}
                    type={"email"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    title={"Correo"}
                    touched={touched.email}
                    errors={errors.email}
                    textError={"*Ingrese el correo electrónico"}
                  />

                  <InputsCustomsForm
                    containerClass={"items-suscriptors"}
                    htmlFor={"phone"}
                    id={"phone"}
                    name={"phone"}
                    type={"text"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    title={"Celular"}
                    touched={touched.phone}
                    errors={errors.phone}
                    textError={"*Digite el número de celular"}
                  />
                </div>

                <InputsCustomsForm
                  containerClass={"textUserForm_descripcion"}
                  htmlFor={"message"}
                  id={"message"}
                  name={"message"}
                  type={"text"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  title={"Mensaje"}
                  touched={touched.message}
                  errors={errors.message}
                  textError={"*Escriba un mensaje"}
                />

                <div className="check_suscriptions_users">
                  <CheckBoxCustom
                    checked={values.terms}
                    onChange={handleChange}
                    id={"terms"}
                    name={"terms"}
                  />
                  <div className="spam_invicibleText">
                    {!values.terms && (
                      <span className="form_suscription_validations">
                        {errors.terms}
                      </span>
                    )}
                  </div>
                </div>

                <div className="button_suscriptions_users">
                  <button
                    type="submit"
                    // value="Send"
                    className="btn_suscriptors"
                    onClick={handleSubmit}
                  >
                    Enviar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
