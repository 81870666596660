import { useMemo } from "react";

//este hook debe ser .jsx para que funcione con el JSX de react getIcon

const useIncludes = (itemRedux ) => {
  const incluyes = useMemo(() => {
    const includes = [];
    if (itemRedux.tiquetes) includes.push("Tickets");
    if (itemRedux.hospedaje) includes.push("Hospedaje");
    if (itemRedux.traslado) includes.push("Traslado");
    if (itemRedux.entradas) includes.push("Entradas");
    if (itemRedux.alimentacion) includes.push("Alimentación");
    return includes;
  }, [itemRedux]);

  const noIncluyes = useMemo(() => {
    const notIncludes = [];
    if (!itemRedux.tiquetes) notIncludes.push("Tickets");
    if (!itemRedux.hospedaje) notIncludes.push("Hospedaje");
    if (!itemRedux.traslado) notIncludes.push("Traslado");
    if (!itemRedux.entradas) notIncludes.push("Entradas");
    if (!itemRedux.alimentacion) notIncludes.push("Alimentación");
    return notIncludes;
  }, [itemRedux]);

  const getIcon  = (item) => {
    switch ( item ) {
      case "Traslado":
        return <ion-icon name="bus-outline"></ion-icon>;
      case "Alimentación":
        return <ion-icon name="fast-food-outline"></ion-icon>;
      case "Entradas":
        return <ion-icon name="ticket-outline"></ion-icon>;
      case "Hospedaje":
        return <ion-icon name="bed-outline"></ion-icon>;
      case "Tickets":
        return <ion-icon name="airplane-outline"></ion-icon>;
      default:
        return <ion-icon name="close-outline"></ion-icon>;
    }
  }

  

  return { incluyes, noIncluyes , getIcon };
};

export default useIncludes;

