import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: "",
  logged: false,
  email : "",
  names : "",
  lastName : "",
  phone : "",
  profile : "",
  status: "",
  tokenPush: "",
  
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
        logged: action.payload.logged,
      };
    },
    logoutUser: (state) => {
      return initialState;
    },
  },
});

export const { setLoggedUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
