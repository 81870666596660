import React from "react";
import "./styles.css";

export const SocialMedia = () => {
  return (
    <>
      <div className="container_social_media">
        <div className="container_fb">
          <a href="https://www.facebook.com/tuviajeapp/">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <div className="overlay">Facebook</div>
        </div>

        <div className="container_gmail">
          <a href="mailto:correo@example.com">
            <ion-icon name="mail-outline"></ion-icon>
          </a>
          <div className="overlay">Email</div>
        </div>

        <div className="container_ig">
          <a href="https://www.instagram.com/tuviajeapp/?hl=es-la">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <div className="overlay">Instagram</div>
        </div>
      </div>

      <div className="container_wp">
        <ion-icon name="logo-whatsapp" 
        onClick={() => {
          window.location.href = "https://wa.me/573106709314";
        }}
        > </ion-icon>
      </div>
    </>
  );
};
