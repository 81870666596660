import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const ordersFacturationProgramming = async (orderID) => {
  // console.log("LO QUE ENTRO AL SERVICIO DE ORDER ID DE PROGRAMACION", orderID);
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          {
            getProgrammingServiceByOrderId(orderId: "${orderID}") {
              _id
              enterprise
              payTotal
              orderID
              status
              createdAt
              statusPayment
              passengers {
                names
              }
              quantity
              price
              programming {
                _id
                start
                puntoFin {
                  name
                }
                tour {
                  origin {
                    name
                  }
                  destination {
                    name
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        // console.log(
        //   "result de la peticion del servicio OrderId",
        //   result.data.getProgrammingServiceByOrderId
        // );
        resolve(result.data.getProgrammingServiceByOrderId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
