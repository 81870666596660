import { CardCharacteristics } from "../CardCharacteristics/CardCharacteristics";
import "./styles.css";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faTicket,
  faTabletAlt,
  faRoute,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const data = [
  {
    tittle: "Rutas de viaje",
    description:
      "Podrás revisar el trayecto de tu viaje en tiempo real por medio de Google Maps desde la aplicación móvil.",
    heightIcon: 40,
    iconName: faRoute,
  },
  {
    tittle: "Pago virtual y presencial",
    description:
      "En Tu Viaje App decides la forma de pago del trayecto que desees elegir, podrás pagar por medio de tu tarjeta crédito o débito, PSE o en efectivo.",
    heightIcon: 40,
    iconName: faCreditCard,
  },
  {
    tittle: "Reserva de cupos",
    description:
      "Realiza tus reservas con seguridad y sé parte de esta experiencia única de TuViaje.",
    heightIcon: 40,
    iconName: faTicket,
  },
  {
    tittle: "Gestiona en la app",
    description:
      "Realiza todo el proceso de encontrar el viaje de tus sueños, comprar y hacer seguimiento de tu trayecto todo por una misma aplicación, Tu Viaje App es la solución que esperabas para el turismo nacional.",
    heightIcon: 40,
    iconName: faTabletAlt,
  },
];

export const Characteristics = () => {
  return (
    <div className="containerCharacteristics">
      <div className="item-one-Characteristics">
        <h1>Características</h1>

        <p>
          Con Tu Viaje App tendrás muchos beneficios para que realices ese viaje
          de tus sueños Conócelos a continuación:
        </p>
      </div>

      <div className="item-two-Characteristics">
        <div className="items_container">
          {data.map((item, index) => {
            return (
              <CardCharacteristics
                key={index}
                tittle={item.tittle}
                description={item.description}
                heightIcon={item.heightIcon}
                iconName={item.iconName}
                border={index + 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
