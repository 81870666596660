import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSearchItems } from "../../../redux/searchItems";
import React, { useState } from "react";
import "./styles.css";

export const Principal = () => {
  const [itemTitle, setItemTitle] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = () => {
    const searchInput = document.getElementById("search");
    const title = searchInput.value;
    setItemTitle(title);

    dispatch(getSearchItems({ searchItemsTitle: title }));

    navigate(`/turismos`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="ContainerHome">
      <h1 className="title">¿A dónde quieres ir?</h1>
      <h1 className="description">
        Disfruta y vive la gran experiencia de conocer Colombia con Tu Viaje
        App
        <br />
        ¡La aplicación móvil para el turismo nacional!
      </h1>

      <div className="divSearch">
        <div className="divLabel">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="IconSearch"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>

          <input
            autoComplete="true"
            type="text"
            name="search"
            id="search"
            placeholder="Busca tus lugares favoritos"
            className="inputSearch"
            required
          />
        </div>

        <div className="divbutton">
          <button
            id="submitSearch"
            type="submit"
            className="button"
            onClick={handleSearch}
          >
            <span>Buscar paquetes</span>
            <i></i>
          </button>
        </div>
      </div>
      <div className="darkbox"></div>
    </div>
  );
};


