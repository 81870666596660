import "./styles.css";

import DatePicker from "react-datepicker";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetSearchItems } from "../../../redux/searchItems";

import "react-datepicker/dist/react-datepicker.css";
const Searcher = ({ setSearchParams, handleSearch , resertData , title  }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [availableSeats, setAvailableSeats] = useState("");

  const dispatch = useDispatch();
  
  return (
    <div className="container_searcher">
      <div className="container_search2">
      <div className="items_row_calendar">


        
        <div className="items_calendar">
          <ion-icon name="ellipse-outline"></ion-icon>
          <input
            className="input_search_turims"
            type="text"
            placeholder="Origen"
            value={origin}
            onChange={(e) => {
              setSearchParams({
                origin: e.target.value,
                destination,
                startDate,
                endDate,
                availableSeats,
              });
              setOrigin(e.target.value);
            }}
          />
        </div>

        <div className="items_calendar">
          <ion-icon name="location-outline"></ion-icon>
          <input
            className="input_search_turims"
            type="text"
            placeholder="Destino"
            value={destination}
            onChange={(e) => {
              setSearchParams({
                destination: e.target.value,
                origin,
                startDate,
                endDate,
                availableSeats,
              });
              setDestination(e.target.value);
            }}
          />
        </div>

        <div className="items_calendar">
          <ion-icon name="calendar-outline"></ion-icon>
          <div className="input_search_turims">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              dateFormat={"dd/MM/yyyy"}
              monthsShown={2}
              minDate={new Date()}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
              selectsRange={true}
              // className="day_"
              onChange={(dates) => {
                const [start, end] = dates;
                setSearchParams({
                  startDate: start,
                  origin,
                  destination,
                  endDate: end,
                  availableSeats,
                });
                setStartDate(start);
                setEndDate(end);
              }}
              placeholderText="Selecciona fechas"
            />
          </div>
        </div>

        <div className="items_calendar">
          <ion-icon name="person-outline"></ion-icon>
          <input
            className="input_search_turims"
            type="number"
            placeholder="Pasajeros"
            value={availableSeats}
            onChange={(e) => {
              setSearchParams({
                availableSeats: e.target.value,
                origin,
                destination,
                startDate,
                endDate,
              });

              setAvailableSeats(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="items_two_calendar">
        <button className="btn_searcher" onClick={handleSearch}>
        { title } 
        </button>

        <div className="div_circle">
          <button
            className="container_logo_trash"
            onClick={() => {
              // console.log("Eliminar datos", setSearchParams);
              setStartDate(null);
              setEndDate(null);
              setAvailableSeats("");
              setOrigin("");
              setDestination("");

              setSearchParams({
                origin: "",
                destination: "",
                startDate: "",
                endDate: "",
                availableSeats: "",
              });
              resertData();

            }}


          >
            <ion-icon name="trash-outline"></ion-icon>
          </button>
        </div>
        </div>
        </div>
    </div>
  );
};

export default Searcher;
