import "./styles.css";

export const ItemPrincipalDescriptionCard   = ({ typeProgramg , ubication , days , nights , acomodacion , origen}) => {
        if (typeProgramg == "ProgrammingPopulateResponse") {
          return (
            <div className="items_render_dynamic_ReservationCard">
              <div className="title_reservation_card">
                Ciudad de origen
                <div className="items_destination_ReservationCard">
                  <ion-icon name="location-outline"></ion-icon> <p>{origen}</p>
                </div>
              </div>
    
              <div className="title_reservation_card">
                Ciudad de destino
                <div className="items_destination_ReservationCard">
                  <ion-icon name="business-outline"></ion-icon> <p>{ubication}</p>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="items_render_dynamic2_ReservationCard">
              <div className="items_turims_ReservationCard">
                <ion-icon name="location-outline"></ion-icon> <p>{ubication}</p>
              </div>
              <div className="items_turims_ReservationCard">
                <div className="item_day_ReservationCard">
                  <ion-icon name="sunny-outline"></ion-icon>
                  <p>Dia(s):{days}</p>
                </div>
    
                <div className="item_day_ReservationCard">
                  <ion-icon name="moon-outline"></ion-icon>
                  <p>Noche(s):{nights}</p>
                </div>
              </div>
              <div className="items_turims_ReservationCard">
                <ion-icon name="bed-outline"></ion-icon> <p>{acomodacion}</p>
              </div>
            </div>
          );
        }
      };



