import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectDateUser : null
}

const  dateSlice = createSlice({
    name : "dateSelectUser", 
    initialState,

    reducers : {
        getDateUser : (state , action) => {
            return {
                ...state,
                ...action.payload
            };
        }
    },
});

export const { getDateUser } = dateSlice.actions;
export default dateSlice.reducer;

