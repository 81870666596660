import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { formatDateYY } from "../../utils/formatDate";
import "./styles.css";

const SelectCalendarDates = ({
  availableDates,
  showCalendar,
  selectDate,
  avaliableDaySelected,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [enabledDates, setEnabledDates] = useState([]);
  useEffect(() => {
    const availableDatesArray = availableDates.map((date) => {
      formatDateYY(date.fecha);
      return formatDateYY(date.fecha);
    });

    const highlightedDates = availableDatesArray.map((date) => new Date(date));

    const allDates = Array.from({ length: 365 }, (_, i) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + i);
      return currentDate;
    });

    const enabled = allDates.filter((date) =>
      highlightedDates.some(
        (highlightedDate) =>
          date.getDate() === highlightedDate.getDate() &&
          date.getMonth() === highlightedDate.getMonth() &&
          date.getFullYear() === highlightedDate.getFullYear()
      )
    );
    setEnabledDates(enabled);
  }, [availableDates]);

  return (
    <div className="container_tourimsCalendar">
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);

        }}
        includeDates={enabledDates}
        inline
      />
      <div className="container_tourimsCalendarButtons">
        <button
          style={{ backgroundColor: "#505050", color: "white" }}
          onClick={() => {
            // console.log("se presiono cancelar", startDate);
            showCalendar();
          }}
        >
          {" "}
          Cancelar
        </button>
        <button
          style={{ backgroundColor: "#EC920B", color: "white" }}
          onClick={() => {
            // console.log("se presiono aceptar");
            showCalendar();
            selectDate(startDate ? startDate : "");
          }}
        >
          {" "}
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default SelectCalendarDates;
