import React from 'react';
import { NavBar } from '../MainInformation/NavBar/NavBar';
import Footer from '../MainInformation/Footer/Footer';

export const NavBarLayout = ({ children , NavAndFoo }) => {
  return (
    <>
      { NavAndFoo ? <NavBar /> : null}
      {children}
      { NavAndFoo ? <Footer /> : null}
    </>
  );
};


