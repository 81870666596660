import { Routes, Route } from "react-router-dom";
import { Banner } from "../screen/Banner/Banner";

const TravelExplorer = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Banner />} />     
      </Routes>
    </>
  );
};

export default TravelExplorer;

