import React from "react";
import "./styles.css";
import imagen from "../../../imgs/aboutUs.png";

import { useSpring, animated } from "react-spring";

export const AboutUs = () => {
  const { y } = useSpring({
    from: { y: 0 },
    to: { y: 40 },
    loop: { reverse: true },
    config: { duration: 800 },
  });
  return (
    <div className="containerAboutUs">
      <div className="aboutUs_item_one">
        <h1>Sobre Nosotros</h1>
        <p className="aboutUs-description">
          Somos un servicio cómodo y seguro para viajar entre los diferentes
          municipios de Colombia. Contamos con una red de transporte especial
          básico y de lujo, para que vivas una experiencia confiable y
          satisfactoria que garantizará la llegada a tu lugar de destino.
          Nuestra propuesta va más allá de llevarte de un origen a un destino,
          es entender tus necesidades de viaje y que puedas gestionar todo lo
          que necesitas a través de nuestra App, estos son algunos ítems de
          nuestra propuesta <span>¡contáctanos y descúbrela!</span>
        </p>
      </div>
      <div className="aboutUs_item_two">
        <animated.div
          className=""
          style={{
            transform: y.to((value) => `translateY(${value}px)`),
            width: "100%",
          }}
        >
          <img src={imagen} className="aboutUs-image" />
        </animated.div>
      </div>
    </div>
  );
};
