import React from "react";
import "./styles.css";
import Footer from "../Footer/Footer";

export const PoliciesAndConditions = () => {
  return (
    <div className="container_PoliciesAndConditions">
      <div className="containe_texts_PoliciesAndConditions">
        <div>
          <h5>TÉRMINOS Y CONDICIONES</h5>
        </div>
        <div>
          <p>
            <b>TUVIAJEAPP.CO</b> es un dominio de Internet y marca registrada
            ante la Superintendencia de Industria y Comercio de la República de
            Colombia, cuya titularidad corresponde a TUVIAJE AGENCIA DE VIAJES Y
            OPERADOR TURÍSTICO S.A.S, sociedad comercial legalmente constituida
            bajo las leyes colombianas.
            <br />
            Por medio del presente documento, se establecen los términos y
            condiciones generales que el USUARIO DIGITAL debe tener en cuenta y
            que son aplicados por TUVIAJE en el sitio en internet 
            <b>TUVIAJEAPP.CO</b>
            <br />
            El ingreso, consulta y/o utilización de parte del USUARIO DIGITAL
            del sitio en internet TUVIAJEAPP.CO o de sus aplicaciones, implica
            su ACEPTACIÓN EXPRESA de todas y cada una de las siguientes
            condiciones.
          </p>
        </div>
        <div>
          <h5>TÉRMINOS Y CONDICIONES GENERALES DE USO DEL PORTAL WEB</h5>
        </div>
        <div>
          <p>
            <b>1. </b>El USUARIO DIGITAL, declara que es mayor de edad (mayor de
            18 años) y dispone de la capacidad legal necesaria para contratar
            los destinos ofertados en la web TUVIAJEAPP.CO, así mismo, acepta y
            entiende la totalidad de las condiciones aquí enunciadas para
            utilizar esta web y contratar los servicios ofertados, como objeto
            vinculante a este acuerdo.
            <br />
            <b>2. </b> Para el acceso al sitio web, el
            <b>USUARIO DIGITAL</b> debe contar con acceso a internet, computador
            personal, teléfono inteligente, tableta o cualquier otro dispositivo
            que permita acceder al sitio en internet. TUVIAJE no tiene
            responsabilidad alguna en daños o desperfectos que ocurran en los
            dispositivos que utilice el USUARIO DIGITAL para acceder al sitio en
            internet, ni asume valor alguno por los costos de conexión a
            internet.
            <br />
            <b>3. </b> Todos los contenidos, marcas, logos, dibujos y demás
            distintivos gráficos, auditivos, electrónicos, slogan y similares
            que figuran en la página web de TUVIAJEAPP.CO, se hallan protegidos
            por los derechos de propiedad intelectual (Derechos de Autor y
            Propiedad Industrial) que son expresamente reservados por TUVIAJE o,
            en su caso, por las personas o empresas que figuran como autores o
            titulares de los derechos. La violación de los anteriores derechos
            será perseguida de conformidad con la legislación vigente.
            <br />
            Por lo tanto, queda prohibida la reproducción, explotación,
            alteración, distribución o comunicación pública por cualquier título
            o medio de la totalidad de los contenidos de la página web
            TUVIAJEAPP.CO para usos diferentes de la legítima información o
            contratación por los USUARIO DIGITALES de los servicios ofertados.
            En todo caso, será necesario el consentimiento previo y por escrito
            de TUVIAJE.
            <br />
            <b>4. </b>Los links o enlaces con otras páginas web, que aparezcan
            en las páginas del sitio WEB TUVIAJEAPP.CO, se ofrecen a efectos
            meramente informativos al USUARIO DIGITAL, por lo que TUVIAJE no se
            responsabiliza sobre los productos, servicios o contenidos que se
            oferten o suministren en las páginas de destino ubicados en otro
            dominio.
            <br />
            <b>5. </b>TUVIAJE hace todos los esfuerzos necesarios dentro de sus
            medios para ofrecer la información contenida en el sitio en internet
            de forma veraz y sin errores tipográficos. En caso que en algún
            momento se produjera algún error de este tipo, ajeno en todo momento
            a la voluntad de TUVIAJE, se procederá a su corrección. De existir
            un error tipográfico en alguno de los precios mostrados y algún
            USUARIO DIGITAL hubiera tomado la decisión de compra basándose en
            dicho error, TUVIAJE se lo comunicará al USUARIO DIGITAL lo más
            pronto posible y la compra se rescindirá sin ningún costo por su
            parte, o le ofrecerá la oportunidad de mantener su reserva con el
            precio comercial correcto.
            <br />
            <b>6. </b>Nuestro sitio en internet utiliza servidores publicitarios
            con el fin de facilitar contenidos publicitarios que el USUARIO
            DIGITAL visualiza en nuestras páginas. Dichos servidores
            publicitarios utilizan cookies que le permiten adaptar los
            contenidos publicitarios a los perfiles demográficos de los USUARIO
            DIGITALES. En ningún caso se obtienen datos personales a través de
            estas cookies.
            <br />
            <b>7. </b>Al haber efectuado la reserva el USUARIO DIGITAL acepta
            expresamente que conoce todas y cada una de las condiciones
            indicadas en la presente página y en la correspondiente a cada una
            de las páginas escogidas.
            <br />
          </p>
        </div>
        <div>
          <h5>CONDICIONES ESPECIALES Y RESPONSABILIDADES</h5>
        </div>
        <div>
          <p>
            <b>1. </b>Este sitio en internet que pertenece a TUVIAJE AGENCIA DE
            VIAJES Y OPERADOR TURÍSTICO S.A.S., declara que actúa como
            intermediario entre las personas (en adelante USUARIO DIGITAL) y las
            sociedades, entidades prestadoras del servicio de transporte
            especial, turísticos o de cualquier otro ofertado a través del sitio
            en internet. <br />
            TUVIAJE AGENCIA DE VIAJES Y OPERADOR TURÍSTICO S.A.S no presta
            servicios de transporte, alojamiento ni complementarios. En este
            sentido, nos comprometemos únicamente a cumplir con los servicios de
            intermediación mencionados, con las salvedades especificadas en
            estos Términos y Condiciones, por lo que TUVIAJE no se
            responsabiliza por el incumplimiento total, parcial o defectuoso de
            los servicios ofertados a través de este sitio en internet a cargo
            de los prestadores de estos, ni por imprevistos ocasionados por
            asuntos o hechos de fuerza mayor, caso fortuito o eventos tales como
            modificaciones unilaterales, huelgas, condiciones climáticas,
            atrasos, cierres de vías causados por la autoridad o por terceros,
            terremotos, asuntos de salubridad, condiciones de seguridad,
            factores políticos, negación de permisos de ingreso, retiro del
            viajero por conductas que atenten contra la realización del viaje,
            ni como por los perjuicios materiales, personales o morales que
            pueda sufrir el pasajero por pérdida, daño o hurto de equipaje, ni
            por accidentes, enfermedades o fallecimientos, enunciados a vía de
            ejemplo. De todo ello, o cualquier otro que le origine discrepancia
            al USUARIO DIGITAL, si así lo desea, deberá reclamar directamente
            ante las empresas o entidades que hacen oferta de servicios a través
            de esta plataforma. No obstante, TUVIAJE colaborará al USUARIO
            DIGITAL en cuanto le sea posible.
            <br />
            <b>2. </b>Cada servicio publicado en el sitio en internet señala las
            condiciones de prestación a cargo de cada operador, forma de pago y
            plazo. Respecto a servicios complementarios, visitas, espectáculos,
            conciertos, eventos deportivos y/o culturales, las condiciones de
            prestación de tales servicios, valor, forma de pago y plazo se
            sujetan a las condiciones del organizador de estos.
            <br />
            <b>3. </b>El USUARIO DIGITAL es el único responsable de la custodia
            de su equipaje y documentos de viaje. TUVIAJE le informará al
            pasajero las restricciones de piezas y/o peso aplicables por el
            transportador, siempre y cuando sean previamente publicadas por
            estos, siendo responsabilidad del pasajero acatarlas.
            <br />
            <b>4. </b>Las condiciones de las reservas de viajes terrestres en
            transporte especial, están descritas en las Condiciones Generales
            para viajes.
            <br />
            <b>5. </b>La inscripción como USUARIO DIGITAL registrado de
            TUVIAJEAPP.CO implica el conocimiento y aceptación expresa de las
            presentes condiciones generales y de las condiciones particulares
            determinadas para cada servicio. La contratación de un servicio
            turístico concreto ofertado por los proveedores de servicios o
            viajes combinados que figuran en TUVIAJEAPP.CO, implica la
            aceptación explícita de las condiciones generales de contratación
            del proveedor del que se trate.
            <br />
            El documento en el que se formalice el contrato será archivado en
            una base datos accesible únicamente a TUVIAJE, como responsable de
            la misma. El USUARIO DIGITAL podrá identificar y corregir los
            errores cometidos en la introducción de datos comunicando a TUVIAJE
            tal circunstancia.
          </p>
        </div>
        <div>
          <h5>CONDICIONES DE LAS OFERTAS CON PAGO ONLINE</h5>
        </div>
        <div>
          <p>
            <b>1. </b>Todos los precios y tarifas presentadas en la web de
            TUVIAJE, están sujetos a disponibilidad, cambios y vigencia sin
            previo aviso. Aplican restricciones y condiciones para cada tarifa
            publicada según su vigencia y demás particularidades de la oferta.
            <br />
            <b>2. </b>Las ofertas presentes en TUVIAJEAPP.CO se confirman con
            número de tiquete y reserva de cupo una vez el usuario haya
            realizado el pago. En caso de que cuando se realice el pago no
            queden sillas disponibles en el bus que seleccionó el usuario el
            equipo de TUVIAJE procederá a comunicarse con el cliente para
            ofrecerle otra alternativa o su respectivo reembolso.
            <br />
            <b>3.</b>Cuando se pretenda modificar la reserva, presente, pasada o
            futura y ésta haya sido confirmada, TUVIAJEAPP.CO cobrará los
            respectivos gastos de gestión equivalentes. La modificación será
            comunicada por e-mail (comercial@tuviajeapp.co) al prestador de
            servicio correspondiente, con 5 días hábiles de antelación a la
            fecha de viaje. También se entiende como modificación el cambio de
            nombre del cliente y la modificación de las fechas del viaje. De
            igual manera, las cancelaciones supondrán unos gastos de gestión de
            TUVIAJE.
            <br />
            <b>4. </b>sistemas de reservas las empresas de transporte no se
            pueda generar el pasaje TUVIAJE procederá a hacer el respectivo
            reembolso siguiendo el proceso de reembolso establecido, de acuerdo
            al medio de pago empleado para la compra del viaje.
            <br />
            <b>5. </b>El usuario digital declara que conoce y acepta en su
            integridad estas condiciones, las cuales constituyen el acuerdo
            único, total y excluyente de cualquier pacto o disposición legal en
            contrario, acerca de los términos, condiciones y restricciones de
            los servicios contratados.
            <br />
            Al usar nuestro sitio de internet, como cualquier otro sitio que
            facilite transacciones en línea, EL USUARIO DIGITAL debe tener en
            cuenta lo siguiente:
            <br />
            <b>Phishing:</b> Es posible que cualquier persona sea engañada,
            principalmente a través de correos electrónicos fraudulentos, para
            visitar un sitio falso con apariencia similar al nuestro, por medio
            del cual se obtiene información de la tarjeta débito o crédito para
            ser cargada en el sitio falso o en otro distinto. Por ello,
            recomendamos efectuar sus transacciones directamente en el dominio
            digitado en el navegador de su computador o celular.
            <br />
            <b>Malware, spyware:</b> Es posible que en el computador, celular o
            dispositivo mediante el cual EL USUARIO DIGITAL realiza la
            transacción, tenga instalado sin conocimiento previo, algún software
            espía o mal intencionado que capture todo lo digitado por el teclado
            o capture información de dispositivos de entrada y sean enviados a
            alguna red o host en internet. Por ello recomendamos realizar las
            transacciones en equipos conocidos o seguros, que cuenten con
            programas antivirus o antimalware debidamente actualizados.
          </p>
        </div>
        <div>
          <h5>LEY APLICABLE</h5>
        </div>
        <div>
          <p>
            <b>1. </b>Los presentes Términos y Condiciones" son regidos por las
            normas del ordenamiento civil mercantil y demás leyes y normas
            aplicables incluyendo el uso y la costumbre.
            <br />
            <b>2. </b>El USUARIO DIGITAL acepta que la legislación aplicable al
            funcionamiento de este servicio es la colombiana. Serán competentes
            para conocer de las divergencias que se deriven de la interpretación
            o aplicación de este clausulado los Jueces y Tribunales del
            domicilio del USUARIO DIGITAL. TUVIAJE se reserva el derecho a
            realizar los cambios que considere oportunos en los términos y
            condiciones establecidos. Las modificaciones se incluirán de forma
            destacada en la página web TUVIAJEAPP.CO
            <br />
            <b>3. </b>Si cualquier cláusula incluida en estas condiciones
            generales fuese declarada, total o parcialmente, nula o ineficaz,
            tal nulidad o ineficacia afectará tan sólo a dicha disposición o a
            la parte de la misma que resulte nula o ineficaz, subsistiendo las
            condiciones generales en todo lo demás, teniéndose tal disposición,
            o la parte de la misma que resultase afectada, por no puesta.
          </p>
        </div>
        <div>
          <h5>TÉRMINOS Y CONDICIONES DE FACTURACIÓN</h5>
        </div>
        <div>
          <p>
            El USUARIO DIGITAL declara aceptar la factura emitida por las
            transacciones en la página en las ocasiones en que el tipo de
            producto y de pago genere para TUVIAJE la obligación legal de
            emitirlas. La factura estará a disposición del USUARIO DIGITAL en
            las oficinas de TUVIAJE, dirección: Calle 83c #17-52 Cali, Colombia,
            a partir del día hábil siguiente a aquel en que se realice la
            transacción, a través de los mecanismos electrónicos o digitales que
            disponga TUVIAJE.
          </p>
        </div>
        <div>
          <h5>TÉRMINOS Y CONDICIONES DE MANEJO DE DATOS PERSONALES</h5>
        </div>
        <div>
          <p>
            <b>1. </b> EL USUARIO DIGITAL, para poder contratar servicios
            contenidos en TUVIAJEAPP.CO, deberá registrarse como USUARIO
            DIGITAL, a cuyos efectos declara que toda la información
            suministrada a la hora de registrarse es verdadera, completa y
            precisa. Así mismo, autoriza expresamente a TUVIAJE AGENCIA DE
            VIAJES Y OPERADOR TURÍSTICO S.A.S., de conformidad con las leyes de
            la República de Colombia, para que proceda a incluir en un sistema
            automatizado los datos de carácter personal que figuran en los
            campos del formulario por resultar necesarios para la adecuada
            prestación de sus servicios.
            <br />
            Se indica que el Responsable de la información suministrada es la
            sociedad Digital TUVIAJE AGENCIA DE VIAJES Y OPERADOR TURÍSTICO
            S.A.S. Dirección Calle 83c # 17 - 52 Cali, Colombia, a efectos del
            ejercicio de los derechos de acceso, rectificación, cancelación y
            oposición previstos en la Ley. Del mismo modo, el USUARIO DIGITAL
            autoriza expresamente la comunicación de dichos datos a terceros
            cuando resulten necesarios para la adecuada prestación de los
            servicios que se contraten respetando en todo momento la legislación
            en vigor.
            <br />
            <b>2. </b>El USUARIO DIGITAL da su consentimiento expreso para
            recibir comunicaciones electrónicas con publicidad y novedades
            comerciales de TUVIAJE y de los productos o servicios que
            comercializa o promociona. TUVIAJE pone a disposición del USUARIO
            DIGITAL los medios de contacto referidos en el párrafo anterior para
            que estos revoquen su consentimiento.
            <br />
            <b>3. </b>El envío y la remisión de datos que se realice por el
            USUARIO DIGITAL a través de la página web TUVIAJEAPP.CO o la
            información que TUVIAJE remita, se encuentra protegida por las más
            modernas técnicas de seguridad electrónica en la red
            <br />
            Así mismo, los datos suministrados y almacenados en nuestras bases
            de datos se encuentran igualmente protegidos por sistemas de
            seguridad que impiden el acceso de terceros no autorizados a los
            mismos. TUVIAJE realiza sus mejores esfuerzos para disponer de los
            procedimientos más actualizados para la eficacia de los sistemas de
            seguridad.
            <br />
            <b>4. </b>El USUARIO DIGITAL es responsable de facilitar sus datos
            correctos, especialmente la dirección de entrega de los pedidos y
            documentos, así como los nombres de los pasajeros que deben
            coincidir exactamente con los que aparecen en su documento de
            identificación o Pasaporte. TUVIAJE no se hace responsable de los
            problemas o gastos generados por errores de esta índole.
            <br />
            <b>5. </b>En los casos en que sea legalmente aplicable y la creación
            y naturaleza de la información contenida en las bases de datos así
            lo exija, TUVIAJE AGENCIA DE VIAJES Y OPERADOR TURÍSTICO S.A.S. se
            encuentra sujeto a las normas y preceptos contenidos en la ley
            estatutaria 1266 de 2008 (Habeas Data) y demás normas en la materia.
          </p>
        </div>
        <div id="privacidad-politicas">
          <h5>POLÍTICA DE PRIVACIDAD</h5>
        </div>
        <div>
          <p>
            <b>INFORMACIÓN Y DATOS PERSONALES REGISTRADOS </b>
            <br />
            Los datos personales que se registran son los siguientes, entre
            otros:
            <br />
            <b>1. </b> Nombre y apellidos.
            <br />
            <b>2. </b> Correo electrónico.
            <br />
            <b>3. </b> Género.
            <br />
            <b>4. </b> Teléfono de contacto.
            <br />
            <b>5. </b> Dirección.
            <br />
            <b>6. </b> Información de tarjeta(s) de crédito (número, entidad
            bancaria, fecha de vencimiento, código de seguridad).
            <br />
            <b>7. </b> Datos personales del titular de la tarjeta (nombres y
            apellidos, tipo y número de identificación).
            <br />
            <b>8. </b> Información del domicilio donde el titular de la tarjeta
            recibe sus extractos bancarios.
            <br />
            <b>9. </b> Información necesaria para facilitar el viaje u otros
            servicios, incluyendo el nombre de acompañante(s) de viaje,
            contactos para casos de accidente o cualquier otra contingencia,
            preferencia en asientos o requerimientos médicos.
            <br />
            <b>10. </b> Información y datos personales recolectados a través de
            encuestas, focus groups u otros métodos de investigación de mercado.
            <br />
            <b>11. </b> Información requerida por los representantes de las
            áreas de ventas y/o de relaciones con el cliente con la finalidad de
            atender solicitudes o reclamos.
            <br />
            En algunos casos, se podrá recolectar datos sensibles. Se informa a
            los titulares que no estarán obligados en ningún evento a autorizar
            el tratamiento de datos sensibles, y por lo tanto la prestación de
            nuestros servicios no está condicionada a la entrega de esta
            información sensible.
            <br />
            Toda las bases de datos de TuViaje están almacenados en un Data
            Center en la nube de operadores locales solo para efectos de
            almacenamiento, seguridad y control. TuViaje solo utiliza la
            información de clientes para transmitirla a los proveedores de los
            servicios contratados por el CLIENTE y no para cederla o
            comercializarla ante terceros no relacionados con estos servicios.
            <br />
            La información suministrada permanecerá en el sistema de TuViaje por
            el término máximo necesario para permitirnos en el cumplimiento de
            las obligaciones legales y/o contractuales a nuestro cargo
            especialmente en materia contable, contractual, fiscal y tributaria
            o para atender las disposiciones aplicables a los aspectos
            administrativos, contables, fiscales, jurídicos e históricos de la
            información.
            <br />
            Presumimos la veracidad de la información suministrada y no
            verificamos, ni asumimos la obligación de verificar la veracidad,
            vigencia, suficiencia y autenticidad de los datos que nos sean
            proporcionados. Por tanto, no asumimos responsabilidad por daños y/o
            perjuicios de toda naturaleza que pudieran tener origen en la falta
            de veracidad, vigencia, suficiencia o autenticidad de la
            información, incluyendo daños y perjuicios que puedan deberse a la
            homonimia o a la suplantación de la identidad. Si nos suministra
            información personal sobre una persona distinta a usted, como su
            cónyuge o un compañero de trabajo, entendemos que cuenta con la
            autorización de dicha persona para suministrarnos sus datos.
            <br />
            Al inscribirse y/o navegar en nuestra página web, nuestros clientes
            y/o visitantes de la página manifiestan expresamente que conocen
            estas políticas y las aceptan, y otorgan su consentimiento para que
            se dé tratamiento a su información.
          </p>
        </div>
        <div id="tratamiento">
          <h5>FINALIDAD QUE TIENE EL TRATAMIENTO DE LOS DATOS PERSONALES</h5>
        </div>
        <div>
          <p>
            Si el CLIENTE nos proporciona sus datos personales, nos autoriza
            para hacer uso de esta información para las finalidades señaladas en
            concordancia con lo previsto en esta Política de Privacidad, y NO
            procederemos a transferir o divulgar la misma fuera de nuestra
            plataforma salvo que (a) usted nos autorice a hacerlo, (b) sea
            necesario para permitir a nuestros contratistas, proveedores o
            agentes prestar los servicios que les hemos encomendado, (c) la
            utilicemos nosotros o terceros para proporcionarle nuestros
            productos o servicios, (d) sea entregada a las entidades que prestan
            servicios de marketing en nuestro nombre o a otras entidades con las
            cuales tenemos acuerdos de mercadeo conjunto, (e) tenga relación con
            una fusión, consolidación, adquisición, desinversión u otro proceso
            de restructuración, (f) implementemos un contrato de transmisión de
            datos personales en los términos del Decreto 1377 de 2013, o (g)
            según sea requerido o permitido por la ley o para las finalidades
            desarrolladas en la presente política de privacidad.
            <br />
            Al aceptar esta Política de Privacidad, los titulares de los datos
            recolectados, autorizan que realicemos el tratamiento de los mismos,
            de forma parcial o total, incluyendo la recolección, almacenamiento,
            grabación, uso, circulación, procesamiento, supresión, transmisión
            y/o transferencia a terceros países de los datos suministrados, para
            la ejecución de las actividades relacionadas con los servicios y
            productos adquiridos, tales como, procesar, confirmar, cumplir y
            proveer los servicios y/o productos adquiridos, directamente y/o con
            la participación de terceros proveedores de productos o servicios
            así como para promocionar y publicitar nuestras actividades,
            productos y servicios, realizar transacciones, efectuar reportes a
            las distintas autoridades administrativas de control y vigilancia
            nacionales o internacionales, autoridades de policía o judiciales,
            entidades bancarias y/o compañías aseguradoras, para fines
            administrativos internos y/o comerciales tales como, investigación
            de mercados, auditorías, reportes contables, análisis estadísticos,
            realización de la reserva, modificaciones, reembolsos, atención de
            consultas, quejas y reclamos, pago de compensaciones e
            indemnizaciones, registros contables, correspondencia, procesamiento
            y verificación de tarjetas de crédito, débito y otros instrumentos
            de pago, identificación de fraudes y prevención de lavado de activos
            y de otras actividades delictivas y demás finalidades indicadas en
            este documento, entre otros.
            <br />
            En razón de nuestra actividad, estamos obligadas a suministrar una
            serie de datos de los CLIENTES a las autoridades que regulan el
            transporte público terrestre o entidades de seguridad nacionales,
            antes de la salida de los buses o en la llegada en cada ciudad de
            destino o en cualquier momento después de ejecutado el contrato de
            transporte. Por regla general, la información se refiere a los datos
            de identidad de los pasajeros que viajan a bordo así como los
            contenidos en sus respectivos documentos de viaje.
            <br />
            Adicionalmente, nuestros clientes, en su calidad de titulares de los
            datos recolectados, al aceptar esta política de privacidad, nos
            autorizan para:
            <br />
            <b>1. </b> Permitir el acceso a la información y datos personales a
            los auditores o terceros contratados para llevar a cabo procesos de
            auditoría interna o externa propios de la actividad comercial que
            desarrollamos.
            <br />
            <b>2. </b> Utilizar la información recibida para fines de mercadeo
            de sus productos y servicios, y de los productos y servicios de
            terceros con los que tengamos una relación de negocios.
            <br />
            <b>3. </b> Consultar y actualizar los datos personales, en cualquier
            tiempo, con el fin de mantener actualizada dicha información.
            <br />
            <b>4. </b> Suministrar los datos personales a las autoridades de
            control y vigilancia de policía o judiciales, en virtud de un
            requerimiento legal o reglamentario y/o usar o revelar esta
            información y datos personales en defensa de sus derechos y/o su
            patrimonio en cuanto dicha defensa tenga relación con los productos
            y/o servicios contratados por sus clientes y consumidores.
            <br />
            <b>5. </b> Contratar con terceros el almacenamiento y/o
            procesamiento de la información y datos personales para la correcta
            ejecución de los contratos celebrados con nosotros, bajo los
            estándares de seguridad y confidencialidad a los cuales estamos
            obligados.
            <br />
            <b>6. </b> Recibir comunicaciones electrónicas con publicidad y
            novedades comerciales de TuViaje y de los productos o servicios que
            comercializa o promociona. TuViaje pone a disposición del CONSUMIDOR
            los medios de contacto referidos en el párrafo anterior para que
            estos revoquen su consentimiento.
            <br />
            Los niños, niñas y adolescentes menores de edad, podrán ser CLIENTES
            de los productos y servicios que ofrecemos, siempre y cuando actúen
            a través de o debidamente autorizados por sus padres o por quienes
            tengan la patria potestad del menor. Velaremos por el uso adecuado
            de los datos personales de los niños, niñas y adolescentes menores
            de edad, garantizando que en el tratamiento de sus datos se respete
            el interés superior de ellos, y sus derechos fundamentales y en lo
            posible, teniendo en cuenta su opinión, como titulares de sus datos
            personales.
            <br />
            Con la aceptación de la presente Política de Privacidad, los
            titulares de los datos personales nos autoriza para hacerles llegar
            por distintos medios y vías (incluyendo correo electrónico, SMS o
            mensajes de texto, etc.) información de productos y servicios, y de
            ofertas de productos y servicios que creemos pueden serle de
            interés.
            <br />
            Nuestro sitio web utiliza servidores publicitarios con el fin de
            facilitar contenidos publicitarios que el CLIENTE visualiza en
            nuestro sitio web. Dichos servidores publicitarios utilizan cookies
            que le permiten adaptar los contenidos publicitarios a los perfiles
            demográficos de los CLIENTES y conocer la procedencia, actividades y
            preferencias de nuestros CLIENTES al navegar en la web, incrementar
            la funcionalidad y la accesibilidad de los sitios web, verificar que
            los clientes cumplan con los criterios requeridos para procesar sus
            solicitudes y para adaptar sus productos y servicios a las
            necesidades de los consumidores. Estas cookies pueden ser
            deshabilitadas y eliminadas cuando se desee. Para este efecto, puede
            consultar y/o solicitar la ayuda del navegador de Internet que
            utilice.
          </p>
        </div>
        <div>
          <h5>SEGURIDAD Y CONFIDENCIALIDAD CON TUVIAJE</h5>
        </div>
        <div>
          <p>
            El envío y la remisión de datos que se realice por el CLIENTE a
            través de la WEB TUVIAJE.CO o la información que TuViaje recolecte y
            haga tratamiento, se encuentra protegida por técnicas de seguridad
            electrónica en la red. Asimismo los datos suministrados y
            almacenados en nuestra plataforma, se encuentran igualmente
            protegidos por sistemas de seguridad que impiden el acceso de
            terceros NO autorizados a los mismos. TuViaje realiza sus mejores
            esfuerzos para disponer de los procedimientos más actualizados para
            la eficacia de los sistemas de seguridad.
            <br />
            En este sentido, bajo apoyo de herramientas tecnológicas de
            seguridad e implementamos prácticas de seguridad reconocidas en la
            industria, que incluyen: transmisión y almacenamiento de información
            sensible a través de mecanismos seguros, tales como cifrado, uso de
            protocolos seguros; aseguramiento de componentes tecnológicos,
            restricción de acceso a la información sólo a personal autorizado,
            respaldo de información, prácticas de desarrollo seguro de software,
            entre otros.
            <br />
            Los terceros contratados por nosotros están igualmente obligados a
            adherirse y dar cumplimiento a las políticas y manuales de seguridad
            de la información, así como a los protocolos de seguridad que
            aplicamos a todos nuestros procesos.
            <br />
            Todo contrato con terceros que involucre el tratamiento de
            información y datos personales, incluye un acuerdo de
            confidencialidad que detalla sus compromisos para la protección,
            cuidado, seguridad y preservación de la confidencialidad, integridad
            y privacidad de la misma.
          </p>
        </div>
        <div>
          <h5>DERECHOS DE LOS CLIENTES Y PROCEDIMIENTOS</h5>
        </div>
        <div>
          <p>
            Con la aceptación de los Términos y Condiciones de los cuales hace
            parte integral esta Política de Privacidad, usted manifiesta libre,
            expresa y previamente haber sido informado sobre los derechos que la
            ley le concede como titular de sus datos personales y que se
            enuncian a continuación:
            <br />
            <b>1. </b> Conocer, actualizar y rectificar sus datos personales
            frente a la entidad responsable del tratamiento o encargada del
            tratamiento de sus datos personales.
            <br />
            <b>2. </b> Solicitar prueba de la autorización otorgada al
            responsable del tratamiento salvo cuando expresamente se exceptúa
            como requisito para el tratamiento.
            <br />
            <b>3. </b> Ser informado por el responsable del tratamiento o el
            encargado del tratamiento, previa solicitud, respecto del uso que le
            ha dado a los datos personales.
            <br />
            <b>4. </b> Presentar ante la Superintendencia de Industria y
            Comercio quejas por infracciones al régimen de protección de datos
            personales.
            <br />
            <b>5. </b> Revocar la autorización y/o solicitar la supresión del
            dato personal en los términos de la Ley 1581 de 2012.
            <b></b>
            <b>6. </b> Acceder en forma gratuita una vez por mes a sus datos
            personales que hayan sido objeto de tratamiento, en los términos de
            la normatividad vigente.
            <br />
            Los procedimientos para el ejercicio de sus derechos serán los
            siguientes:
            <br />
            <b>CONSULTAS</b>
            <br />
            Los titulares (las personas autorizadas o causahabientes) podrán
            consultar su información personal que repose en nuestra plataforma,
            caso en el cual les suministraremos la información solicitada,
            previa verificación de la legitimación para presentar dicha
            solicitud. La consulta será atendida en un término máximo de diez
            (10) días hábiles contados a partir de la fecha de recibo de la
            misma. Cuando no fuere posible atender la consulta dentro de dicho
            término, se le informarán los motivos de la demora, señalando la
            fecha en que se atenderá su consulta, la cual en ningún caso podrá
            superar los cinco (5) días hábiles siguientes al vencimiento del
            primer término.
            <br />
            <b>RECLAMOS</b>
            Si los titulares, las personas autorizadas o los causahabientes
            consideran que la información contenida en nuestra plataforma debe
            ser objeto de corrección, actualización o supresión, o cuando
            adviertan el presunto incumplimiento de cualquiera de los deberes
            contenidos en la Regulación, podrán presentar un reclamo ante
            nosotros, el cual será tramitado bajo las siguientes reglas:
            <br />
            <b>1. </b> Su reclamo deberá formularse mediante solicitud dirigida
            a TuViaje, con su identificación, la descripción de los hechos que
            dan lugar al reclamo, su dirección, y acompañando los documentos que
            se quiera hacer valer. Si el reclamo resulta incompleto, lo
            requerimos dentro de los cinco (5) días siguientes a la recepción
            del reclamo para que subsane las fallas. Transcurridos dos (2) meses
            desde la fecha del requerimiento, sin que usted presente la
            información requerida, entenderemos que ha desistido del reclamo. En
            caso de que no seamos competentes para resolver su reclamo, dará
            traslado a quien corresponda en un término máximo de dos (2) días
            hábiles y le informará oportunamente.
            <br />
            <b>2. </b> De ser procedente, una vez recibido el reclamo completo,
            se incluirá en la base de datos una leyenda que diga "reclamo en
            trámite" y el motivo del mismo, en un término no mayor a dos (2)
            días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo
            sea decidido.
            <br />
            <b>3. </b> El término máximo para atender el reclamo será de quince
            (15) días hábiles contados a partir del día siguiente a la fecha de
            su recibo. Cuando no fuere posible atender el reclamo dentro de
            dicho término, se le informarán los motivos de la demora y la fecha
            en que se atenderá su reclamo, la cual en ningún caso podrá superar
            los ocho (8) días hábiles siguientes al vencimiento del primer
            término.
            <br />
            El área de atención de peticiones, consultas o reclamos ante la cual
            el titular de la información puede ejercer sus derechos es:
            <br />
            <b>ATENCION AL CLIENTE</b>
            <br />
            Teléfono: +57 304 4771614
            <br />
            Correo de contacto: comercial@tuviajeapp.co
            <br />
            <b>MODIFICACIONES Y VIGENCIA DE LA POLÍTICA DE PRIVACIDAD</b>
            <br />
            Podremos modificar en cualquier momento los términos y condiciones
            de estas políticas de privacidad y confidencialidad y/o las
            prácticas de envío de e-mails. Si decidimos introducir algún cambio
            material a nuestras políticas de privacidad, se informará en la
            página web y publicando una versión actualizada de las políticas de
            privacidad. Las bases de datos tendrán vigencia indefinida, de
            conformidad las finalidades y usos de la información.
          </p>
        </div>
      </div>
    </div>
  );
};
