import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const TotalFormspassengersSlice = createSlice({
    name: "totalFormsPassengers",
    initialState,

    reducers: {
        setFormPassengers: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const {   setFormPassengers   } = TotalFormspassengersSlice.actions;
export default TotalFormspassengersSlice.reducer;
