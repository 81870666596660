import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import { setFormPassengers } from "../../redux/totalFormsPassengersSlice";
import "./styles.css";

const FormCustom = ({ start }) => {
  const userCounter = useSelector((state) => state.passengers);


  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(() => {
    const initialForms = [];
    for (let i = 0; i < userCounter.totalPassengers; i++) {
      initialForms.push({
        cel: false,
        email: false,
        lastnames: false,
        names: false,
        numberId: false,
        phone: false,
        selectId: 0,
        selectOrigen: 0,
      });
    }
    return initialForms;
  });

  useEffect(() => {
    dispatch(setFormPassengers(formValues));
  }, [formValues, dispatch]);

  const handleChange = (e, index) => {
    const { name, value , selectedIndex  } = e.target;
    const [key, idx] = name.split("-");

    setFormValues((prevFormValues) => {
      const updatedFormValues = [...prevFormValues];
      updatedFormValues[index] = {
        ...updatedFormValues[index],
        [key]: e.target.tagName === 'SELECT' ? selectedIndex -1 : value,
      };
      return updatedFormValues;
    });
  };

  return (
    <>
      <Accordion className="accordion_container">
        {formValues.map((form, index) => (
          <Accordion.Item key={index} eventKey={index.toString()}>
            <Accordion.Header>{`Persona #${index + 1}`}</Accordion.Header>
            <Accordion.Body>
              <div className="container_FormCustom">
                <Formik
                  initialValues={formValues[index]}
                >
                  <Form style={{ color: "black" }}>
                    <div className="container_FormCustom__form__input">
                      <div className="items_formcustom_colum">
                        <label htmlFor={`names-${index}`}>Nombre(s)</label>
                        <Field
                          type="text"
                          name={`names-${index}`}
                          id={`names-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div className="items_formcustom_colum">
                        <label htmlFor={`lastnames-${index}`}>
                          Apellido(s)
                        </label>
                        <Field
                          style={{ color: "black" }}
                          type="text"
                          name={`lastnames-${index}`}
                          id={`lastnames-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>

                    <div className="container_FormCustom__form__input">
                      <div className="items_formcustom_colum">
                        <label htmlFor={`selectId-${index}`}>
                          Tipo documento
                        </label>
                        <Field
                          as="select"
                          name={`selectId-${index}`}
                          id={`selectId-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="" >
                            Seleccione...
                          </option>
                          <option value="CC">Cedula ciudadania</option>
                          <option value="CE">Cedula extrangera</option>
                          <option value="P">Pasaporte</option>
                          <option value="V">Visa</option>
                          <option value="TI">Tarjeta de identidad</option>
                          <option value="RC">Registro civil</option>
                        </Field>
                      </div>
                      <div className="items_formcustom_colum">
                        <label htmlFor={`numberId-${index}`}>
                          Número de documento
                        </label>
                        <Field
                          type="text"
                          name={`numberId-${index}`}
                          id={`numberId-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>

                    <div className="container_FormCustom__form__input">
                      <div className="items_formcustom_colum">
                        <label htmlFor={`email-${index}`}>Correo</label>
                        <Field
                          type="email"
                          name={`email-${index}`}
                          id={`email-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div className="items_formcustom_colum">
                        <label htmlFor={`selectOrigen-${index}`}>
                          Lugar de recogida
                        </label>
                        <Field
                          as="select"
                          name={`selectOrigen-${index}`}
                          id={`selectOrigen-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="">Recogida</option>
                          {start.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>

                    <div className="container_FormCustom__form__input">
                      <div className="items_formcustom_colum">
                        <label htmlFor={`cel-${index}`}>Celular</label>
                        <Field
                          type="tel"
                          name={`cel-${index}`}
                          id={`cel-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                      <div className="items_formcustom_colum">
                        <label htmlFor={`phone-${index}`}>Teléfono</label>
                        <Field
                          type="tel"
                          name={`phone-${index}`}
                          id={`phone-${index}`}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default FormCustom;
