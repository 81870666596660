
const  regex = {

     regexEmail : 
     /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
     regexPassword :
      /^[a-zA-Z0-9*@\.\-_]{5,10}$/,
      regexName :
      /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
      regexIdentification :
      /^[0-9]{6,10}$/,
      regexPhone :
      /^[0-9]{7,10}$/,
      regexCelphone :
      /^[0-9]{10}$/,
}

export default regex;