import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;
const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const suscriptions = async (email) => {
  // console.log("email", email);

  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation createSubscriptor($email: String!) {
            createSubscriptor(input: { email: $email }) {
              result {
                email
                id
                __typename
              }
            }
          }
        `,
        variables: {
          email: email
        },
      })
      .then((result) => {
        // console.log("result mano", result);
        if (result.data.createSubscriptor.result !== null) {
          // console.log("entro en el 1");
          resolve(result.data.createSubscriptor.result);
        } else {
          // console.log("entro en el 2");
          reject("No se pudo crear el suscriptor");
        }
      })
      .catch((error) => {
        // console.log("error", error);
        reject(error);
      });
  });
};