import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const ordersFacturation = async (orderID) => {

  // console.log("LO QUE ENTRO AL SERVICIO DE ORDER ID DE TURISMO", orderID);
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          {
            getTourismServicesByOrderId(orderId: "${orderID}") {
              enterprise
              payTotal
              orderID
              status
              statusPayment
              passengers {
                names
              }
              quantity
              price
              tourism {
                nombrepaq
                origen {
                  name
                }
                destino {
                  name
                }
              }
              start
              end
            }
          }
        `,
      })
      .then((result) => {
        // console.log(
        //   "result de la peticion del servicio OrderId",
        //   result.data.getTourismServicesByOrderId
        // );
        resolve(result.data.getTourismServicesByOrderId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
