import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;
const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const loginPassenger = async (email, password, deviceId, tokenPush) => {

  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation login($input: LoginPassengerInput) {
            loginPassenger(input: $input) {
              result {
                id
                names
                lastName
                email
                phone
                profile
                password
                status
                tokenPush
              }
              message
            }
          }
        `,
        variables: {
          input: {
            email: email,
            password: password,
            deviceId: deviceId,
            tokenPush: tokenPush,
          },
        },
      })
      .then((result) => {
        // console.log("result mano", result);
        if (result.data.loginPassenger.result !== null) {
          // console.log("entro en el 1");
          resolve(result.data.loginPassenger.result);
        } else {
          // console.log("entro en el 2");
          reject(result.data.loginPassenger.message);
        }
      });
  });
};
