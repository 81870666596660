import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import {
  formatDate,
  formatDateTime,
  isoDateTourimsDay,
} from "../../utils/formatDate";
import { formatPrice } from "../../utils/formatPrice";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { createTourismService } from "../../services/TourimsSerices";
import { createProgrammingService } from "../../services/programmingServices";

const ReservationModal = ({
  showModal,
  closeModal,
  selectedCodeMethodPay,
  selectedCostMethodPay,
}) => {
  const itemRedux = useSelector((state) => state.itemProduct);
  const userCounter = useSelector((state) => state.passengers);
  const allPassengers = useSelector((state) => state.totalFormsPassengers);
  const DateUser = useSelector((state) => state.dateSelectUser.selectDateUser);
  const userName = useSelector((state) => state.user);

  const navigate = useNavigate();
  // console.log(
  //   "estoy en el MODALRESERVATION con el item redux",
  //   userCounter,
  //   itemRedux,
  //   allPassengers,
  //   DateUser
  // );

  return (
    <div className="container_modalReservation">
      <Modal
        show={showModal}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        className="modal_reservarion"
      >
        <Modal.Header>
          <Modal.Title>Confirmar reserva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="title_modalReservation">
            <h2>{itemRedux.name} </h2>
          </div>

          <div className="container_items_modalReservation">
            {itemRedux.paqueteDiario == true ? (
              <div className="container_items_modalReservationday">
                <div className="items_modalReservation">
                  <h5>Fecha Salida</h5>
                  <h5>{DateUser}</h5>
                </div>
                <div className="items_modalReservation">
                  <h5>Hora de salida</h5>
                  <h5> {formatDateTime(itemRedux.startProgram)}</h5>
                </div>
              </div>
            ) : (
              <>
                <div className="items_modalReservation">
                  <h5>Fecha ida</h5>
                  <h5>{formatDate(itemRedux.startProgram)}</h5>
                </div>
                <div className="items_modalReservation">
                  <h5>Fecha regreso</h5>
                  <h5>{formatDate(itemRedux.endProgram)}</h5>
                </div>
              </>
            )}
          </div>

          <div className="container_items_modalReservation">
            {itemRedux.paqueteDiario == false ? (
              <>
                <div className="items_modalReservation">
                  <h5>Hora de salida</h5>
                  <h5> {formatDateTime(itemRedux.startProgram)}</h5>
                </div>
                <div className="items_modalReservation">
                  <h5>Hora de regreso</h5>
                  <h5>{formatDateTime(itemRedux.endProgram)}</h5>
                </div>
              </>
            ) : null}
          </div>

          <div className="container_items_modalReservation_two">
            <div className="items_modalReservation">
              {userCounter.adults > 0 ? (
                <h5> Servicio adulto(s)... # {userCounter.adults} </h5>
              ) : null}
              {itemRedux.priceChildren != "" && userCounter.children > 0 ? (
                <h5> Servicio niño(s)... # {userCounter.children} </h5>
              ) : null}
              <h5>Costo transacción...</h5>
              <h5>Total</h5>
            </div>
            <div className="items_modalReservation">
              {userCounter.adults > 0 ? (
                <h5>
                  {" "}
                  {itemRedux.priceDcto != null
                    ? formatPrice(itemRedux.priceDcto * userCounter.adults)
                    : formatPrice(itemRedux.price * userCounter.adults)}{" "}
                </h5>
              ) : null}

              {itemRedux.priceChildren != "" && userCounter.children > 0 ? (
                <h5>
                  {formatPrice(
                    itemRedux.priceChildren == "No existe"
                      ? itemRedux.price * userCounter.children
                      : itemRedux.priceChildren * userCounter.children
                  )}{" "}
                </h5>
              ) : null}
              <h5>{formatPrice(selectedCostMethodPay)}</h5>
              <h5>
                {" "}
                {formatPrice(
                  selectedCostMethodPay +
                    (itemRedux.priceChildren == "No existe"
                      ? userCounter.children * itemRedux.price
                      : userCounter.children * itemRedux.priceChildren) +
                    (itemRedux.priceDcto != null
                      ? userCounter.adults * itemRedux.priceDcto
                      : userCounter.adults * itemRedux.price)
                )}
              </h5>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn_modalReservation_secondary"
            onClick={() => {
              // console.log(
              //   "---------------------se presiono el boton cancelar",
              //   userName,
              //   DateUser,
              //   itemRedux,
              //   "los fomularios",
              //   allPassengers
              // );
              closeModal();
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className="btn_modalReservation_primary"
            onClick={() => {
              // console.log("se presiono el boton pagar");
              // console.log(
              //   "---------------------se presiono el boton pagar",
              //   //   obj,
              //   itemRedux
              //   //   "LA FECHA DEL USUARIO",
              //   //   DateUser,"originalDateuser",
              //   //   // "los fomularios",
              //   //   // allPassengers
              //   //   // navigate(`/payment-notification`)
              //   //   isoDateTourimsDay(DateUser, itemRedux.startProgram)
              // );

              if (itemRedux?.typename === "ProgrammingPopulateResponse") {
                let objProgramming = {
                  price:
                    selectedCostMethodPay +
                    userCounter.children * itemRedux.price +
                    (itemRedux.priceDcto != null
                      ? userCounter.adults * itemRedux.priceDcto
                      : userCounter.adults * itemRedux.price),
                  method: selectedCodeMethodPay,
                  programming: itemRedux.id,
                  quantity: userCounter.totalPassengers,
                  passengers: allPassengers,
                  passenger: userName.id,
                };
                // console.log("estoy en la programacion", objProgramming);

                createProgrammingService(objProgramming)
                  .then((result) => {
                    // console.log("resultado de la reserva", result.result.url);

                    const paymentURL = result.result.url;

                    if (paymentURL) {
                      window.location.href = paymentURL;
                    } else {
                      console.error("No se recibió una URL de pago válida");
                    }
                  })
                  .catch((error) => {
                    // console.log("error al reservar", error);
                  });
              } else {
                let obj = {
                  price:
                    selectedCostMethodPay +
                    userCounter.children * itemRedux.priceChildren +
                    (itemRedux.priceDcto != null
                      ? userCounter.adults * itemRedux.priceDcto
                      : userCounter.adults * itemRedux.price),
                  method: selectedCodeMethodPay,
                  tourism: itemRedux.id,
                  quantity: userCounter.totalPassengers,
                  passengers: allPassengers,
                  passenger: userName.id,
                  datePassenger: isoDateTourimsDay(
                    DateUser,
                    itemRedux.startProgram
                  ),
                };
                createTourismService(obj)
                  .then((result) => {
                    // console.log("resultado de la reserva", result.result.url);

                    const paymentURL = result.result.url;

                    if (paymentURL) {
                      window.location.href = paymentURL;
                    } else {
                      console.error("No se recibió una URL de pago válida");
                    }
                  })
                  .catch((error) => {
                    console.log("error al reservar", error);
                  });
              }
            }}
          >
            Pagar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReservationModal;
