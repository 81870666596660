import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const methodPaymentAvailable = async () => {
  
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
        {
            getMethods{
              cod
              name
              percent
              value
              __typename
              image
            }
          }
            `,
      })
      .then((result) => {
        if (result.data.getMethods !== null) {
          resolve(result.data.getMethods);
        } else {
          reject("No se encontraron metodos de pago");
        }
      });
  });
};
