import React from 'react';
import Lottie from 'lottie-react';
import animationData2 from '../../assets/busFondoNegro.json';
import './styles.css';

export const LottieAnimation = () => {
  return (
    <div className='container-lottie'>
      <Lottie animationData={animationData2} loop={true} autoplay={true} style={{ height: 200, width: 200 }} />   
    </div>
  );
};


