import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import itemProductReducer from "./ItemSlice";
import passengersReducer from "./passengersSlice";
import totalFormsPassengersReducer from "./totalFormsPassengersSlice"
import dateUserReducer from "./dateSlice"
import searchItems from "./searchItems";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import {thunk} from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", 
  "itemProduct", 
  "totalpassengers",
  "totalFormsPassengers", 
  "dateSelectUser",
  "searchItems"

],
};

const rootReducer = combineReducers({
  user: userReducer,
  itemProduct: itemProductReducer,
  passengers: passengersReducer,
  totalFormsPassengers: totalFormsPassengersReducer,
  dateSelectUser : dateUserReducer,
  searchItems : searchItems

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunk
      },
      serializableCheck: false,
    }).concat(thunk),
});

