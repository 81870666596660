import logo from "../../../imgs/TuViajeLogo.png";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { SubMenu } from "./SubMenu";
import { SocialMedia } from "../../SocialMedia/SocialMedia";
import { navigation } from "./nav";
import { resetSearchItems } from "../../../redux/searchItems";

export const NavBar = ({ socialMedia = false }) => {
  const [scrolled, setScrolled] = useState(false);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(navigation);

  // useEffect(() => {
  //   console.log("E1111111111111111");
  //   const handleScroll = () => {
  //     const currentScrollPos = window.pageYOffset;
  //     setScrolled(currentScrollPos > 0);
  //     setShowSocialMedia(prevScrollPos > currentScrollPos);
  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  const handleScroll = ({ target, name }) => {
    // console.log("ENTRO AL SCROL HPTA", target, name);

    const screenWidth = window.innerWidth;
    if (screenWidth == 320 && name == "App") {
      target = 668;
    } else if (screenWidth == 375 && name == "App") {
      target = 645;
    }
    else if (screenWidth == 425 && name == "App") {
      target = 643;
    } 
    else if (screenWidth == 768 && name == "App") {
      target = 573;
    } else if (screenWidth == 1024 && name == "App") {
      target = 540;
    } else if (screenWidth == 320 && name == "Nosotros") {
      target = 330;
    }
   else if (screenWidth == 375 && name == "Nosotros") {
    target = 320;
  }
  else if (screenWidth == 425 && name == "Nosotros") {
    target = 320;
  }
  else if (screenWidth == 768 && name == "Nosotros") {
    target = 320;
  }
  else if (screenWidth == 1024 && name == "Nosotros") {
    target = 315;
  }
    // console.log("target", target);

    setTimeout(() => {
      const targetPercentage = target;
      // console.log("TARGET FINAL ES : ", targetPercentage);
      const windowHeight = window.innerHeight;
      const targetPosition = (windowHeight * targetPercentage) / 100;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }, 100);
  };

  return (
    <nav
      className="navbar bg-dark border-bottom border-body navbar-expand-lg bg-body-tertiary"
      data-bs-theme="dark"
    >
      <div className="container-fluid">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {navigation.map((item, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  to={item.link}
                  className="nav-link"
                  onClick={() => handleScroll(item)}
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <ion-icon
                  name="person-outline"
                  className="user_login"
                ></ion-icon>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <SubMenu />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
