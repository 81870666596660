import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;
const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const getAllTurims = async () => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: gql`
          {
            getAllTourism {
              result {
                nombrepaq
                id
                acomodacion
                tiquetes
                hospedaje
                traslado
                alimentacion
                entradas
                precio
                precioDcto
                precioNino
                dias
                noches
                dcto
                descripcion
                destino {
                  name
                }
                origen {
                  name
                }
                imagen
                banner
                gallery
                cupos
                disponibles
                tipo
                ida
                vuelta
                paqueteDiario
                cuposPorDia{
                  fecha
                  cuposDisponibles
                  disponibles
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        if (result.data.getAllTourism.result !== null) {
          resolve(result.data.getAllTourism.result);
        } else {
          reject(result.data.getAllTourism.message);
        }
      });
  });
};
