import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const downLoadPDF = (orderId, product) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`
          mutation {
            createServicesPDF(
              input: { orderId: "${orderId}", product: "${product}" }
            )
          }
        `,
      })
      .then((result) => {
        if (result.data.createServicesPDF !== null) {
          resolve(result.data.createServicesPDF);
        } else {
          reject("No se encontraron metodos de pago");
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};
