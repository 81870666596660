import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
const server = process.env.REACT_APP_CLIENT_URL;

const client = new ApolloClient({
  uri: `${server}`,
  cache: new InMemoryCache(),
});

export const createProgrammingService = async (obj) => {
  // console.log("objeto", obj);

  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: gql`mutation{
                        createProgrammingServices(input:{
                            price:"${obj.price}"
                            method:"${obj.method}"
                            programming:"${obj.programming}"
                            quantity: ${obj.quantity}
                            passenger:"${obj.passenger}"
                            passengers:[${obj.passengers.map((passenger) => {
                              return `{
                                    names:"${passenger.names}"
                                    lastnames:"${passenger.lastnames}"
                                    selectId:"${passenger.selectId}"
                                    numberId:"${passenger.numberId}"
                                    email:"${passenger.email}"
                                    phone:"${passenger.phone}"
                                }`;
                            })}]
                        }){
                            result{
                                url
                            }
                            message
                        }
                    }`,
      })
      .then((result) => {
        if (result.data.createProgrammingServices !== null) {
          resolve(result.data.createProgrammingServices);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
