import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  // nombrepaq: "",
  day: "",
  night: "",
  alimentacion: "",
  tiquetes: "",
  hospedaje: "",
  traslado: "",
  entradas: "",
  tipo: "",
  origen: "",
  destination:  "",
  price: "",
  // preceoDcto : "",
  priceChildren: "",
  gallery: [],
  imagen : "",
  banner : "",
  typeProduct : "",
  acomodacion : "",
  typeTransport : "",
  available : "",
  startProgram : "",
  endProgram : "",
  origenTurims : [],
};

const itemSlice = createSlice({
  name: "itemProduct",
  initialState,

  reducers: {
    getItemProduct: (state , action) => {
        return {
            ...state,
            ...action.payload,
            };
        }
    },
});

export const { getItemProduct } = itemSlice.actions;
export default itemSlice.reducer;
