import React from 'react'
import ImageGallery from "react-image-gallery";
import { useSelector } from "react-redux";


const linkURL = process.env.REACT_APP_IMAGE_URL

import './styles.css'
import "react-image-gallery/styles/css/image-gallery.css"

const Gallery = () => {

  const itemRedux = useSelector((state) => state.itemProduct);

  // console.log("ESTO ES LO QUE RECIBE EL GALLERY", itemRedux ,linkURL );

  const galleryData = []

  if(itemRedux.gallery.length > 0){
    itemRedux.gallery.map((img, index) => {
      galleryData.push({
        original:linkURL + img,
        thumbnail: linkURL + img
      })
    })
  }

  return (
    
    <div className="container_gallery">
        <h1>Galería</h1>
          <ImageGallery 
          showThumbnails={true}
          showPlayButton={false}
          showFullscreenButton={true}
          items={galleryData} />    
     
    </div>
  )
}

export default Gallery