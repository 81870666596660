import { useState, useEffect } from "react";
import "./styles.css";
import Searcher from "../../components/TravelCoordinator/Searcher/Searcher";
import { CarruselCustom } from "../../components/TravelCoordinator/CarroselCustom/CarroselCustom";
import { loading } from "../../utils/loading";
import { dataFilter } from "../../hooks/dataFilter";
import { searchDataFilter } from "../../hooks/searchDataFilter";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchItems } from "../../redux/searchItems";
import { LottieAnimation } from "../../components/LottieAnimation/LottieAnimation";

export const TravelCoordinator = ({
  title,
  secondTitle,
  description = "Por medio de nuestra app adquiere el viaje de ¡tus sueños!, te brindamos una experiencia única, cómoda y segura para ti y tu familia ¡conoce todo lo que tenemos para ti!",
  data,
  path,
  titleButon,
}) => {
  const dispatch = useDispatch();

  const searchItems = useSelector((state) => state.searchItems);

  const [filtroSeleccionado, setFiltroSeleccionado] = useState("");
  const [filteredSearchData, setFilteredSearchData] = useState(data);

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [nextPage, setNextPage] = useState(false);
  const pageSize = 10;

  const filteredData = dataFilter(filteredSearchData, filtroSeleccionado);

  const [searchParams, setSearchParams] = useState({
    origin: "",
    destination: "",
    startDate: null,
    endDate: null,
    availableSeats: "",
    title: searchItems.searchItemsTitle.toString(),
  });


  useEffect(() => {
    // console.log("searchItems", searchItems);

    const timer = setTimeout(() => {
      setNextPage(false);
    }, 3000);

    return () => clearTimeout(timer);

  }, [nextPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // console.log("efect 1");
    setCurrentPage(1);
    setTimeout(() => {
      handleSearch();
      dispatch(resetSearchItems());
    }, 1000);

    return () => clearTimeout(timer);
  }, [data]);

  useEffect(() => {
    if (isLoading || nextPage) {
      document.body.classList.add("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isLoading , nextPage]);

  useEffect(() => {
    // console.log("efect 2");
    setFilteredSearchData(filteredData);
    setCurrentPage(1);
  }, [filtroSeleccionado]);

  useEffect(() => {
    // console.log("efect 3");
    // console.log("PATH", path);
    setIsLoading(true);
    setCurrentPage(1);
    setSearchParams({
      title: "",
    });
    setFilteredSearchData(data);
    setNextPage(false);
  }, [path]);

  const paginatedData = () => {
    const filtered = filteredData.length > 0 ? filteredSearchData : [];
    // console.log("----------la data filtrada ? ", filtered);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filtered.slice(startIndex, endIndex);
  };

  // const calculateDynamicHeight = () => {
  //   const numRows = Math.ceil(paginatedData().length / 2);
  //   const itemHeight = 18;
  //   const marginTop = 5;
  //   const marginBottom = 2;
  //   const dynamicHeight = numRows * itemHeight + marginTop + marginBottom + 9;
  //   return `${dynamicHeight}rem`;
  // };

  const handleSearch = () => {
    // console.log("searchParams", searchParams);
    const filteredData = searchDataFilter(data, searchParams);
    // console.log("data filtrada en el boton ", filteredData);
    setCurrentPage(1);
    setFilteredSearchData(filteredData);

    dispatch(resetSearchItems());
  };

  const resertData = () => {
    loading();
    setFilteredSearchData(data);
  };

  return (
    <div key={path} className="container_TravelCoordinator">
      {isLoading && <LottieAnimation />}
      {nextPage && <LottieAnimation />}

      <div className="item_one_TravelCoordinator">
        <div className="items_TravelCoordinator">
          <h1 className="title_TravelCoordinator">{title} </h1>
          <h4 className="description_TravelCoordinator">{description}</h4>
        </div>
        <Searcher
          setSearchParams={setSearchParams}
          handleSearch={handleSearch}
          setCurrentPage={setCurrentPage}
          resertData={resertData}
          title={ titleButon }
        />
      </div>

      <div
        className="item_two_TravelCoordinator"
      >
        <div className="seconds_items_Travel_Coordinator">
          <h1 className="second_Title_Travel_Coordinator">{secondTitle}</h1>
          <div className="Order_Programming">
            <h1> Orderna por : </h1>
            <select
              className="select_TravelCoordinator"
              onChange={(e) => setFiltroSeleccionado(e.target.value)}
              style={{ fontSize: "1rem" }}
            >
              <option value="">Ninguno</option>
              <option value="lowerPrice">Menor precio</option>
              <option value="higherPrice">Mayor Precio</option>
              <option value="origin-AZ">Origen A-Z</option>
              <option value="origin-ZA">Origen Z-A</option>
              <option value="destination-AZ">Destino A-Z</option>
              <option value="destination-ZA">Destino Z-A</option>
              <option value="available">Cupos</option>
            </select>
          </div>
        </div>

        <>
          <CarruselCustom data={paginatedData()} />
        </>

        <div className="pagination">
          {data.length >= 1 && (
            <>
              {filteredSearchData.length >= 1 && (
                <button
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1)
                    setNextPage(true)
                  }}
                >
                  Anteriores
                </button>
              )}
              {filteredSearchData.length > 10 &&
                [
                  ...Array(
                    Math.min(
                      Math.ceil(
                        filteredData.length /
                          (pageSize < 10 ? pageSize - 1 : pageSize)
                      ),
                      10
                    )
                  ).keys(),
                ].map((pageNumber) => (
                  <span
                    key={pageNumber + 1}
                    onClick={() => {
                      setCurrentPage(pageNumber + 1)
                      setNextPage(true)

                    }}
                    className={
                      currentPage === pageNumber + 1 ? "activePage" : ""
                    }
                  >
                    { pageNumber + 1 }
                  </span>
                ))}
              {filteredSearchData.length >= 1 && (
                <button
                  disabled={filteredSearchData.length <= 10}
                  onClick={() => {
                    setCurrentPage(currentPage + 1)
                    setNextPage(true)
                  }}
                >
                  Siguiente
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
