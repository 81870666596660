import React, { useState, useEffect } from "react";
import "./styles.css";
// import FormCustom from "../FormCustom/FormCustom";
import { useSelector, useDispatch } from "react-redux";
import {
  setAdultPassengers,
  setChildPassengers,
} from "../../redux/passengersSlice";
import Swal from "sweetalert2";

export const PassengerQuantity = ({ available, showCounter }) => {
  const dispatch = useDispatch();
  // const itemRedux = useSelector((state) => state.itemProduct);
  // const passangers = useSelector((state) => state.passengers);
  const [userAdult, setUserAdult] = useState(0);
  const [userChild, setUserChild] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  // console.log("General", passangers.totalPassengers , "niños",passangers.children ,"adultos", passangers.adults);
  const isAddButtonDisabled = totalUsers >= available;

  useEffect(() => {
    const total = userAdult + userChild;
    setTotalUsers(total);
  }, [userAdult, userChild]);

  return (
    <div className="containerFormUser">
      {showCounter ? (
        <div className="adduser-form-items">
          <div className="addUser-title">
            <ion-icon name="person-add-outline"></ion-icon>
            <h2>Cantidad de personas</h2>
          </div>

          <div className="addPerson-form">
            <div>
              <div className="counter-off-persons">
                <button
                  disabled={userAdult === 0}
                  onClick={() => {
                    setUserAdult((prevUserAdult) => {
                      const updatedValue = prevUserAdult - 1;
                      dispatch(setAdultPassengers(updatedValue));
                      if (updatedValue === 0 && userChild === 0) {
                        Swal.fire({
                          icon: "info",
                          title: "Seleccionar pasajeros",
                          text: "Ingrese la cantidad de pasajeros para continuar con la reserva",
                          showConfirmButton: false,
                          timer: 2000,
                        });
                      }
                      return updatedValue;
                    });
                  }}
                  className={
                    userAdult == 0
                      ? "disabledButton"
                      : "button_PassengerQuantity"
                  }
                >
                  <ion-icon style={{ color: 'white' }} name="remove-outline"></ion-icon>
                </button>
              </div>
              <h5>{userAdult}</h5>
              <div className="counter-on-persons">
                <button
                  disabled={isAddButtonDisabled || userAdult >= available}
                  onClick={() => {
                    setUserAdult(userAdult + 1);
                    dispatch(setAdultPassengers(userAdult + 1));
                  }}
                  className={
                    userAdult >= available || userChild + userAdult >= available
                      ? "disabledButton"
                      : "button_PassengerQuantity"
                  }
                >
                  <ion-icon style={{ color: 'white' }} name="add-outline"></ion-icon>
                </button>
              </div>
            </div>
            <h3>Adultos</h3>
          </div>

          <div className="addPerson-form">
            <div>
              <div className="counter-off-persons">
                <button
                  disabled={userChild === 0}
                  onClick={() => {
                    setUserChild((prevUserChild) => {
                      const updatedValue = prevUserChild - 1;
                      dispatch(setChildPassengers(updatedValue));
                      if (userAdult === 0 && updatedValue === 0) {
                        Swal.fire({
                          icon: "info",
                          title: "Seleccionar pasajeros",
                          text: "Ingrese la cantidad de pasajeros para continuar con la reserva",
                          showConfirmButton: false,
                          timer: 2000,
                        });
                      }
                      return updatedValue;
                    });
                  }}
                  className={
                    userChild === 0
                      ? "disabledButton"
                      : "button_PassengerQuantity"
                  }
                >
                  <ion-icon style={{ color: 'white' }} name="remove-outline"></ion-icon>
                </button>
              </div>
              <h5>{userChild}</h5>
              <div className="counter-on-persons">
                <button
                  disabled={isAddButtonDisabled || userChild >= available}
                  onClick={() => {
                    setUserChild(userChild + 1);
                    dispatch(setChildPassengers(userChild + 1));
                  }}
                  className={
                    userChild >= available || userChild + userAdult >= available
                      ? "disabledButton"
                      : "button_PassengerQuantity"
                  }
                >
                  <ion-icon style={{ color: 'white' }} name="add-outline"></ion-icon>
                </button>
              </div>
            </div>
            <h3>Niños</h3>
          </div>
        </div>
      ) : null}


    </div>
  );
};
