import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const PrivateRouter = ({ children }) => {
  const {logged} = useSelector((state) => state.user);
  return logged ? children : <Navigate to="/banner" />;
};

PrivateRouter.propTypes = {
  children: PropTypes.node,
};
