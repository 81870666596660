import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'

import { Provider } from "react-redux";
import  {store}  from "./redux/store.js";
import { PersistGate } from "redux-persist/integration/react";

import { persistStore } from "redux-persist";

//bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";
//bootstrap js
import "bootstrap/dist/js/bootstrap.bundle.js";

const persistor = persistStore(store);

ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate persistor={persistor}>
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
  </PersistGate>
)
